import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "../../components/OtpInput";
import BlurAnimation from "../../components/BlurAnimation";
import axios from "axios";
import CircularLoader from "../../components/CircularLoader";
import { ToastContainer } from "react-toastify";
import { showToast } from "../../utils/showToast";
import { useDispatch } from "react-redux";
import { setAccessToken } from "../../redux/authSlice";

const Otp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [otpStatus, setOtpStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const userData = JSON.parse(localStorage.getItem("regData"));

  const verifyOtp = async () => {
    setLoading(true);
    let otp = `${pin1}${pin2}${pin3}${pin4}`;
    // if (userData.otp === Number(otp)) {
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/auth/register`,
        {
          firstname: userData.firstname,
          lastname: userData.lastname,
          email: userData.email,
          password: userData.password,
          otp: otp,
          phone: 0,
          country: "",
          address: {
            city: "",
            street: "",
            zipcode: 0,
          },
          timeZone: {
            value: "Etc/GMT",
            label: "(GMT+0:00) UTC",
            offset: 0,
            abbrev: "GMT",
            altName: "British Standard Time",
          },
          businessInfo: {
            businessName: "",
            businessEmail: "",
            vatID: "",
            fullAdress: "",
          },
        },
        {
          withCredentials: true,
        }
      )
      .then(async (res) => {
        setOtpStatus(false);
        setLoading(false);

        if (res.data.user_id) {
          sessionStorage.setItem("token", res.data.accessToken);
          sessionStorage.setItem("user_id", res.data.user_id);
          dispatch(setAccessToken(res.data.accessToken || ""));
          if (res.data.botInfo.length === 0) {
            navigate("/create-bot");
            return;
          }
          if (res.data.botInfo.length > 0) {
            navigate("/dashboard");
            return;
          }
          showToast("info", "Your Account created Successfully", {
            style: { backgroundColor: "blue" },
            toastId: "register",
            containerId: "Auth",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reSendOTP = async () => {
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/send_otp`, {
        email: userData.email,
        emailType: "register",
        emailSubject: "Registration OTP verification",
      })
      .then(() => {
        showToast("success", "Resend OTP Successfully", {
          style: { backgroundColor: "green" },
          toastId: "reSendOTP",
          containerId: "Auth",
        });
      })
      .catch((err) => {
        console.log(err);

        showToast(
          "error",
          "There is a problem with resending OTP, please try again later",
          {
            style: { backgroundColor: "red" },
            toastId: "reSendOTP",
            containerId: "Auth",
          }
        );
      });
  };

  return (
    <div className=" relative bg-primary min-h-screen">
      <BlurAnimation />
      <ToastContainer containerId="Auth" />
      <div className=" flex items-center justify-between px-8 xl:px-12 py-3">
        <div className=" flex items-center">
          <img
            className=" h-8 w-28"
            alt="logo"
            src={require("../../assets/images/Logo1.png")}
          />
        </div>
        <div className=" hidden lg:block">
          <div className=" flex items-center">
            <h3 className=" text-white">Don't have an account yet? </h3>
            <button
              onClick={() => navigate("/register")}
              className=" bg-secoundry text-white   ml-2 rounded-md cursor-pointer"
            >
              <p className=" py-1 px-2"> Signup</p>
            </button>
          </div>
        </div>
      </div>
      <div className=" flex flex-col items-center pt-20">
        <h2 className=" text-2xl text-white font-bold">Verify your email</h2>
        <div className=" flex flex-col items-center py-4 lg:w-1/2 xl:w-1/3">
          <span className=" text-white">
            We've sent a code to{" "}
            <span className=" text-secoundry">{userData?.email}</span>
          </span>
          <p className=" text-white"> Please Enter the Code Below</p>
        </div>
        <div className=" px-4 md:w-1/2 lg:w-1/4 mt-5">
          <OtpInput
            setPin1={setPin1}
            setPin2={setPin2}
            setPin3={setPin3}
            setPin4={setPin4}
            SetStatus={setOtpStatus}
          />
        </div>
        {otpStatus && <p className=" text-sm text-red-500 mt-7">Wrong OTP </p>}
        <span className=" text-white mt-8 ">
          Haven't received a code?{" "}
          <span
            onClick={() => {
              reSendOTP();
            }}
            className=" text-secoundry cursor-pointer"
          >
            Send again
          </span>
        </span>
        <div className="flex justify-center  w-full ">
          <button
            onClick={() => {
              verifyOtp();
            }}
            className={`${
              loading ? " w-16 h-16 rounded-full " : " w-1/4 rounded-md"
            } bg-secoundry transition-all flex justify-center items-center ease-in-out duration-500 hover:bg-pink-600 font-semibold    py-3 mt-5 text-white `}
          >
            {loading ? <CircularLoader /> : "Next"}
          </button>
        </div>
        <button
          onClick={() => {
            navigate("/register");
          }}
          className=" text-appgray my-4"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default Otp;
