import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import appReducer from "./AppSlice";
import callReducer from "./CallSlice";
import dashboardReducer from "./DashboardSlice";
import contactReducer from "./ContactSlice";
import contentReducer from "./ContentSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    app: appReducer,
    call: callReducer,
    dashboard: dashboardReducer,
    contact: contactReducer,
    content: contentReducer,
  },
});
