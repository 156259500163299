import * as React from "react";
const GoogleCalendarIcon = (props) => (
  <svg
    width="55"
    height="55"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3958_2309)">
      <path
        d="M41.9747 13.0264H13.0273V41.9737H41.9747V13.0264Z"
        fill="white"
      />
      <path
        d="M41.9739 55L55.0003 41.9737L48.4871 40.8624L41.9739 41.9737L40.7852 47.9312L41.9739 55Z"
        fill="#EA4335"
      />
      <path
        d="M0 41.9737V50.6579C0 53.0569 1.94309 55 4.3421 55H13.0263L14.3638 48.4868L13.0263 41.9737L5.92951 40.8624L0 41.9737Z"
        fill="#188038"
      />
      <path
        d="M55.0003 13.0263V4.3421C55.0003 1.94309 53.0572 0 50.6582 0H41.9739C41.1814 3.23036 40.7852 5.60766 40.7852 7.13189C40.7852 8.65613 41.1814 10.6209 41.9739 13.0263C44.8549 13.8513 47.026 14.2638 48.4871 14.2638C49.9482 14.2638 52.1193 13.8513 55.0003 13.0263Z"
        fill="#1967D2"
      />
      <path
        d="M54.999 13.0264H41.9727V41.9737H54.999V13.0264Z"
        fill="#FBBC04"
      />
      <path
        d="M41.9747 41.9736H13.0273V54.9999H41.9747V41.9736Z"
        fill="#34A853"
      />
      <path
        d="M41.9737 0H4.3421C1.94309 0 0 1.94309 0 4.3421V41.9737H13.0263V13.0263H41.9737V0Z"
        fill="#4285F4"
      />
      <path
        d="M18.9625 35.4823C17.8806 34.7514 17.1315 33.6839 16.7227 32.2727L19.2338 31.2379C19.4618 32.1063 19.8598 32.7793 20.4279 33.257C20.9924 33.7346 21.6799 33.9698 22.4832 33.9698C23.3046 33.9698 24.0102 33.7201 24.6 33.2208C25.1898 32.7214 25.4865 32.0846 25.4865 31.3139C25.4865 30.525 25.1753 29.881 24.5529 29.3816C23.9306 28.8823 23.149 28.6326 22.2154 28.6326H20.7644V26.1468H22.0671C22.8704 26.1468 23.547 25.9296 24.097 25.4954C24.647 25.0612 24.922 24.4678 24.922 23.7116C24.922 23.0385 24.6759 22.503 24.1838 22.1014C23.6917 21.6997 23.0694 21.4971 22.3131 21.4971C21.575 21.4971 20.9888 21.6925 20.5546 22.0869C20.1206 22.4823 19.7944 22.9817 19.6065 23.5379L17.1207 22.503C17.45 21.5695 18.0542 20.7445 18.9407 20.0316C19.8273 19.3188 20.9598 18.9606 22.3348 18.9606C23.3516 18.9606 24.2671 19.156 25.0776 19.5504C25.8881 19.9448 26.525 20.4912 26.9845 21.1859C27.444 21.8843 27.672 22.6658 27.672 23.5343C27.672 24.4208 27.4585 25.1698 27.0315 25.7849C26.6046 26.4 26.0799 26.8704 25.4575 27.1997V27.3481C26.2612 27.6794 26.9592 28.2236 27.4766 28.9221C28.0013 29.6277 28.2654 30.4708 28.2654 31.455C28.2654 32.4392 28.0157 33.3185 27.5164 34.0892C27.0171 34.8599 26.3259 35.4678 25.4503 35.9093C24.571 36.3507 23.5832 36.5751 22.4868 36.5751C21.2167 36.5787 20.0444 36.2132 18.9625 35.4823ZM34.3878 23.0204L31.6306 25.0142L30.2519 22.9227L35.1983 19.355H37.0944V36.1843H34.3878V23.0204Z"
        fill="#4285F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_3958_2309">
        <rect width="55" height="55" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default GoogleCalendarIcon;
