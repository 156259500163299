import * as React from "react";
const BotsIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color}
      d="M19 8.975V6a2 2 0 0 0-2-2h-6V2.688a1.488 1.488 0 0 0 .06-2.17 1.5 1.5 0 0 0-2.56 1.06c0 .442.195.836.5 1.11V4H3a2 2 0 0 0-2 2v2.998l-.072.005A.999.999 0 0 0 0 10v2a1 1 0 0 0 1 1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5a1 1 0 0 0 1-1v-1.938a1.004 1.004 0 0 0-.072-.455c-.202-.488-.635-.605-.928-.632ZM5 10c0-1.104.672-2 1.5-2S8 8.896 8 10s-.672 2-1.5 2S5 11.104 5 10Zm8.998 6C12.997 15.997 6 16 6 16v-2s7.001-.002 8.002 0l-.004 2Zm-.498-4c-.828 0-1.5-.896-1.5-2s.672-2 1.5-2 1.5.896 1.5 2-.672 2-1.5 2Z"
    />
  </svg>
);
export default BotsIcon;
