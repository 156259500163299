import * as React from "react";
const CallHistoryIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill={props.color}
      d="M15 10a5 5 0 1 0-4.478-2.774c.091.177.115.38.067.574l-.298 1.113a.65.65 0 0 0 .796.796l1.113-.298a.817.817 0 0 1 .574.067A4.98 4.98 0 0 0 15 10Z"
    />
    <path
      fill={props.color}
      d="m6.038 5.316.649 1.163c.585 1.05.35 2.426-.572 3.349 0 0-1.12 1.119.91 3.148 2.027 2.027 3.147.91 3.147.91.923-.923 2.3-1.158 3.349-.573l1.163.65c1.585.884 1.772 3.106.379 4.5-.837.836-1.863 1.488-2.996 1.53-1.908.073-5.15-.41-8.4-3.66-3.25-3.251-3.733-6.492-3.66-8.4C.05 6.8.7 5.774 1.537 4.937c1.394-1.393 3.616-1.206 4.5.38v-.001Z"
    />
  </svg>
);
export default CallHistoryIcon;
