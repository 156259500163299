import React, { useState } from "react";
import DescriptionInput from "../meetings/DescriptionInput";
import DropDown from "../meetings/DropDown";
import InputFieldM from "../meetings/InputFieldM";
import DaySelector from "../meetings/DaySelector";
import TimezoneSelect from "react-timezone-select";
import { meetingDataSchema } from "../../utils/schemas";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import api from "../../services/axiosInstance";
import { setTopic } from "../../redux/AppSlice";
import { toast } from "react-toastify";

const CreateTopic = ({ setShowModel }) => {
  const pakaData = useSelector(
    (state) => state.content.pakaData
  )?.meetingContent;
  const dispatch = useDispatch();
  const [createMeetingData, setCreateMeetingData] = useState();
  const userData = useSelector((state) => state.app.userData);

  function validateTimeRange(values) {
    const errors = {};
    values.availability.forEach((availability, index) => {
      if (availability.active) {
        availability.meetingTime.forEach((time, timeIndex) => {
          if (time.from >= time.to) {
            if (!errors.availability) errors.availability = [];
            if (!errors.availability[index]) errors.availability[index] = {};
            if (!errors.availability[index].meetingTime)
              errors.availability[index].meetingTime = [];
            errors.availability[index].meetingTime[timeIndex] = {
              from: "From time must be less than To time",
            };
          }
        });
      }
    });
    return errors;
  }

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    // setTouched,
    // setFieldError,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: meetingDataSchema,
    initialValues: {
      user_id:
        createMeetingData != null
          ? createMeetingData.user_id
          : userData?.id || "",
      topic: createMeetingData != null ? createMeetingData.topic : "",
      duration: createMeetingData != null ? createMeetingData.duration : "",
      breakTime: createMeetingData != null ? createMeetingData.breakTime : "",
      location:
        createMeetingData != null
          ? createMeetingData.location
          : { type: "", address: "" },
      calendar: createMeetingData != null ? createMeetingData.calendar : "",
      description:
        createMeetingData != null ? createMeetingData.description : "",
      availability:
        createMeetingData != null
          ? createMeetingData.availability
          : [
              {
                day: "Monday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Tuesday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Wednesday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Thursday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Friday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Saturday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
              {
                day: "Sunday",
                active: false,
                meetingTime: [
                  {
                    from: "",
                    to: "",
                  },
                ],
              },
            ],
      timeZone:
        createMeetingData != null
          ? createMeetingData.timeZone
          : {
              abbrev: "YEKT",
              altName: "Yekaterinburg Standard Time",
              label: "(GMT+5:00) Ekaterinburg",
              offset: 5,
              value: "Asia/Yekaterinburg",
            },
      meetingWindow:
        createMeetingData != null ? createMeetingData.meetingWindow : "",
    },
    enableReinitialize: true,
    validate: validateTimeRange,
    onSubmit: (values, { resetForm }) => {
      createOperationTopic(values, resetForm);
    },
  });

  // Handle meeting availability
  const handleToggle = (day) => {
    const newArray = values?.availability?.map((item, i) => {
      if (item.day === day) {
        return { ...item, active: item.active === true ? false : true };
      } else {
        return item;
      }
    });
    setFieldValue("availability", newArray);
    // setWorkingDaysData(newArray);
  };

  const handleAdd = (day) => {
    const newArray = values?.availability?.map((item, i) => {
      if (item.day === day) {
        return {
          ...item,
          meetingTime: item.meetingTime.concat({ from: "", to: "" }),
        };
      } else {
        return item;
      }
    });

    // setWorkingDaysData(newArray);
    setFieldValue("availability", newArray);
  };

  const handleRemove = (day, itemIndex) => {
    const newArray = values?.availability?.map((item, i) => {
      if (item.day === day) {
        return {
          ...item,
          meetingTime: item.meetingTime.slice(0, -1),
        };
      } else {
        return item;
      }
    });

    setFieldValue("availability", newArray);
  };

  const handleFromTime = (day, time, itemIndex) => {
    const newArray = values?.availability?.map((item, i) => {
      if (item.day === day) {
        const w = item?.meetingTime?.map((it, index) => {
          if (itemIndex === index) {
            return {
              ...it,
              from: time,
            };
          } else {
            return it;
          }
        });
        return {
          ...item,
          meetingTime: w,
        };
      } else {
        return item;
      }
    });
    setFieldValue("availability", newArray);
  };

  const handleToTime = (day, time, itemIndex) => {
    const newArray = values?.availability?.map((item, i) => {
      if (item.day === day) {
        const w = item?.meetingTime?.map((it, index) => {
          if (itemIndex === index) {
            return {
              ...it,
              to: time,
            };
          } else {
            return it;
          }
        });
        return {
          ...item,
          meetingTime: w,
        };
      } else {
        return item;
      }
    });
    setFieldValue("availability", newArray);
  };

  // API call for create topic
  const createOperationTopic = async (topicData, resetForm) => {
    // setDeleteLoader(true);
    api
      .post(`/operation/create_operation`, topicData)
      .then(async ({ data }) => {
        resetForm();
        setShowModel(false);
        dispatch(setTopic(data.operationTopicList));
        // console.log(data);
        // setDeleteLoader(false);
        toast.success("Meeting Topic Created Successfully", {
          theme: "colored",
          position: "top-center",
          autoClose: 1000,
          progress: false,
          hideProgressBar: true,
          style: { backgroundColor: "green" },
        });
      })
      .catch((err) => {
        // setDeleteLoader(false);
        setShowModel(false);
        toast.error("Something went wrong!!", {
          theme: "colored",
          position: "top-center",
          autoClose: 1000,
          progress: false,
          hideProgressBar: true,
        });
        console.log(err, "create Meeting error");
      });
  };

  return (
    <div className="rounded-md bg-white w-[94%] lg:max-w-[50%] lg:min-w-[620px] mx-3 lg:mx-0 md:w-8/12 mt-16 lg:mt-8 py-6 h-fit">
      <div className=" w-full flex flex-col items-center">
        <p className="text-lg text-primary font-bold mb-4">Create A Meeting</p>
      </div>

      <div className=" mx-[2%] mt-5 flex flex-col items-center md:items-start gap-3">
        <div className="w-[95%] lg:w-full">
          <InputFieldM
            onChange={handleChange("topic")}
            onBlur={handleBlur("topic")}
            title="Topic*"
            value={values?.topic}
            placeholder="eg. Demo Paka AI"
            info={pakaData?.topic}
            touched={touched.topic}
            errors={errors.topic}
          />
          <div className=" h-3 pt-1  ">
            {touched.topic && errors.topic && (
              <p className=" text-[11px] text-red-500">{errors.topic}</p>
            )}
          </div>
        </div>
        <div className="w-[95%] lg:w-full mt-2">
          <DropDown
            title={"Duration*"}
            selectedText={values?.duration}
            placeholder={"eg. 60 minutes"}
            options={["10", "15", "20", "30", "45", "60", "90", "120"]}
            setSelectedText={(r) => {
              setFieldValue("duration", r);
            }}
            dropDownClassName="overflow-y-scroll h-56 z-50"
            className={"w-[28rem]"}
            info={pakaData?.duration}
            unitType={"minutes"}
            touched={touched.duration}
            errors={errors.duration}
          />
          <div className=" h-3 pt-1  ">
            {touched.duration && errors.duration && (
              <p className=" text-[11px] text-red-500">{errors.duration}</p>
            )}
          </div>
        </div>
        <div className="w-[95%] lg:w-full mt-2">
          <DropDown
            title={"Break Time*"}
            selectedText={values?.breakTime}
            placeholder={"eg. 60 minutes"}
            options={[
              "5",
              "10",
              "15",
              "20",
              "25",
              "30",
              "45",
              "60",
              "90",
              "120",
            ]}
            setSelectedText={(r) => {
              setFieldValue("breakTime", r);
            }}
            dropDownClassName="overflow-y-scroll h-56 z-50"
            className={"w-[28rem]"}
            info={pakaData?.breakTime}
            unitType={"minutes"}
            touched={touched.breakTime}
            errors={errors.breakTime}
          />
          <div className=" h-3 pt-1  ">
            {touched.breakTime && errors.breakTime && (
              <p className=" text-[11px] text-red-500">{errors.breakTime}</p>
            )}
          </div>
        </div>
        <div className="w-[95%] lg:w-full mt-2">
          <DropDown
            title={"Location*"}
            selectedText={values?.location.type}
            placeholder={"eg. Frontal"}
            options={["Frontal", "Google Meet", "Phone call"]}
            setSelectedText={(r) => {
              setFieldValue("location.type", r);
            }}
            dropDownClassName="overflow-y-scroll min-h-24 max-h-56 z-50"
            className={"w-[28rem]"}
            info={pakaData?.location}
            unitType={""}
            touched={touched.location?.type}
            errors={errors.location?.type}
          />
          <div className=" h-3 pt-1  ">
            {touched.location?.type && errors.location?.type && (
              <p className=" text-[11px] text-red-500">
                {errors.location?.type}
              </p>
            )}
          </div>
          <div>
            {values?.location.type === "Frontal" && (
              <>
                <InputFieldM
                  onChange={handleChange("location.address")}
                  onBlur={handleBlur("location.address")}
                  title="Address"
                  value={values?.location.address}
                  placeholder="eg. Demo Paka AI"
                  info="Enter the address for Frontal."
                />
                <div className=" h-3 pt-1  ">
                  {touched.location?.address && errors.location?.address && (
                    <p className=" text-[11px] text-red-500">
                      {errors.location?.address}
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="w-[95%] lg:w-full mt-10">
        <DescriptionInput
          title="Description*"
          onChange={handleChange("description")}
          onBlur={handleBlur("description")}
          value={values?.description}
          placeholder="eg - Paka AI Demo Session, an enlightening showcase where you'll experience the transformation capabilities of our AI-powered conversational bots and custom interactive voice response (IVR) systems. This session is your opportunity to see how Paka AI can streamline your customer service, ensuring 24/7 availability and significantly enhancing user satisfaction."
          info={pakaData?.description}
          className={`ml-4`}
        />
        <div className=" h-3 pt-1 ml-3 ">
          {touched.description && errors.description && (
            <p className=" text-[11px] text-red-500">{errors.description}</p>
          )}
        </div>
      </div>
      <div className="w-[90%] lg:w-[95%] mx-4 mt-3">
        <p>Availability*</p>
        <div className=" h-3 pt-1 ml-3 ">
          {errors.availability && (
            <p className=" text-[11px] text-red-500">
              From time must be less than To time
            </p>
          )}
        </div>
        {values?.availability?.map((item, index) => (
          <div key={index}>
            <DaySelector
              title={item.day}
              options={item.meetingTime}
              toggleStatus={item.active}
              setFromTime={(r, i) => {
                handleFromTime(item.day, r, i);
              }}
              setToTime={(t, i) => {
                handleToTime(item.day, t, i);
              }}
              handleAdd={() => handleAdd(item.day)}
              onToggleChange={() => handleToggle(item.day)}
              handleRemove={(r) => {
                handleRemove(item.day, r);
              }}
            />
          </div>
        ))}
      </div>

      <div className=" mx-[2%] mt-3 flex flex-col items-center md:items-start gap-3">
        <div className="w-[95%] lg:w-full">
          <h3 className=" text-primary">Timezone*</h3>
          <div className=" w-full">
            <TimezoneSelect
              value={values?.timeZone}
              onChange={(t) => {
                setFieldValue("timeZone", t);
              }}
            />
          </div>
          <div className=" h-3 pt-1  ">
            {touched.timeZone && errors.timeZone && (
              <p className=" text-[11px] text-red-500">{errors.timeZone}</p>
            )}
          </div>
        </div>
        <div className="w-[95%] lg:w-full mt-2">
          <DropDown
            title={"Meeting window*"}
            selectedText={values?.meetingWindow}
            placeholder={"How many days in advance can appointments be made?"}
            options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
            setSelectedText={(r) => {
              setFieldValue("meetingWindow", r);
            }}
            dropDownClassName="overflow-y-scroll h-56 z-50"
            className={"w-[28rem]"}
            info={pakaData?.meetingWindow}
            unitType={""}
          />
          <div className=" h-3 pt-1  ">
            {touched.meetingWindow && errors.meetingWindow && (
              <p className=" text-[11px] text-red-500">
                {errors.meetingWindow}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="w-full flex items-center mt-6 text-center">
        <button
          type="submit"
          onClick={handleSubmit}
          className={`flex justify-center w-8/12 lg:w-1/2 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
        >
          Save
        </button>
        <button
          onClick={() => {
            setShowModel(false);
            setCreateMeetingData(null);
          }}
          className="w-8/12 lg:w-1/2 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry py-2 px-3 rounded-md mx-3 text-primary border border-black"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CreateTopic;
