import * as React from "react";
const PlusIcon = (props) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.50545 0C7.11938 0.00244921 4.83169 0.951186 3.14419 2.63811C1.4567 4.32502 0.507174 6.61239 0.503906 8.99846C0.507986 11.3846 1.45767 13.6718 3.1449 15.359C4.83214 17.0462 7.11935 17.9959 9.50545 18C11.891 17.9959 14.1776 17.046 15.8638 15.3586C17.5501 13.6712 18.4984 11.384 18.5008 8.99846C18.4984 6.61267 17.5495 4.3253 15.8625 2.63829C14.1755 0.951287 11.8912 0.00245073 9.50545 0ZM9.49311 4.89731C9.60258 4.89605 9.7112 4.91679 9.81251 4.95829C9.91383 4.99979 10.0058 5.06122 10.0829 5.13892C10.16 5.21663 10.2208 5.30903 10.2615 5.41065C10.3023 5.51227 10.3222 5.62103 10.3201 5.7305V8.1807H12.7703C12.9872 8.1807 13.1952 8.26685 13.3486 8.42021C13.5019 8.57357 13.5881 8.78157 13.5881 8.99846C13.5881 9.21534 13.5019 9.42334 13.3486 9.5767C13.1952 9.73006 12.9872 9.81622 12.7703 9.81622H10.3201V12.2664C10.3201 12.4833 10.234 12.6913 10.0806 12.8447C9.92725 12.998 9.71925 13.0842 9.50237 13.0842C9.28548 13.0842 9.07748 12.998 8.92412 12.8447C8.77076 12.6913 8.6846 12.4833 8.6846 12.2664V9.8193H6.23132C6.01813 9.82384 5.81154 9.74524 5.65528 9.60013C5.49903 9.45503 5.40537 9.25481 5.39415 9.04187C5.38292 8.82892 5.455 8.61997 5.59512 8.45923C5.73525 8.2985 5.93243 8.1986 6.14492 8.1807C6.1737 8.17917 6.20254 8.17917 6.23132 8.1807H8.67843V5.7305C8.67639 5.62234 8.69583 5.51484 8.73562 5.41424C8.77541 5.31364 8.83477 5.22194 8.91026 5.14445C8.98574 5.06695 9.07586 5.0052 9.17537 4.96278C9.27489 4.92036 9.38493 4.89811 9.49311 4.89731Z"
      fill={props.color}
    />
  </svg>
);
export default PlusIcon;
