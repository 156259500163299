import * as React from "react";
const ContactsIcon = (props) => (
  <svg
    width="21"
    height="24"
    viewBox="0 0 21 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.48377 12C4.48377 12.4887 4.08783 12.8846 3.59916 12.8846H1.82993C1.34126 12.8846 0.945312 12.4887 0.945312 12C0.945312 11.5113 1.34126 11.1154 1.82993 11.1154H3.59916C4.08788 11.1154 4.48377 11.5113 4.48377 12ZM4.48377 6.33846C4.48377 5.84979 4.08783 5.45385 3.59916 5.45385H1.82993C1.34126 5.45385 0.945312 5.84979 0.945312 6.33846C0.945312 6.82713 1.34126 7.22308 1.82993 7.22308H3.59916C4.08788 7.22308 4.48377 6.82713 4.48377 6.33846ZM4.48377 17.6616C4.48377 17.1728 4.08783 16.777 3.59916 16.777H1.82993C1.34126 16.777 0.945312 17.1728 0.945312 17.6616C0.945312 18.1503 1.34126 18.5462 1.82993 18.5462H3.59916C4.08788 18.5462 4.48377 18.1502 4.48377 17.6616ZM20.053 3.33077V20.6692C20.053 22.2326 18.7856 23.5 17.2223 23.5H4.83772C3.27445 23.5 2.00695 22.2326 2.00695 20.6692V19.2538H3.59926C4.47717 19.2538 5.19157 18.5394 5.19157 17.6615C5.19157 16.7836 4.47717 16.0691 3.59926 16.0691H2.00685V13.5922H3.59916C4.47706 13.5922 5.19147 12.8778 5.19147 11.9999C5.19147 11.122 4.47706 10.4076 3.59916 10.4076H2.00685V7.93067H3.59916C4.47706 7.93067 5.19147 7.21626 5.19147 6.33836C5.19147 5.46045 4.47706 4.74605 3.59916 4.74605H2.00685V3.33077C2.00685 1.7675 3.27435 0.5 4.83762 0.5H17.2222C18.7855 0.5 20.053 1.76745 20.053 3.33077ZM8.83114 8.8911C8.83114 10.3985 10.0533 11.6207 11.5607 11.6207C13.0681 11.6207 14.2903 10.3985 14.2903 8.8911C14.2903 7.38371 13.0681 6.16149 11.5607 6.16149C10.0534 6.16154 8.83114 7.38371 8.83114 8.8911ZM15.8069 15.5635C15.8069 13.8888 14.4488 12.5307 12.774 12.5307H10.3477C8.67264 12.5307 7.31454 13.8889 7.31454 15.5635V16.7769H15.8069V15.5635Z"
      fill={props.color}
    />
  </svg>
);
export default ContactsIcon;
