import * as React from "react";
const MeetingsIcon = (props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.10443 2.29163C7.10443 1.91193 6.79662 1.60413 6.41693 1.60413C6.03724 1.60413 5.72943 1.91193 5.72943 2.29163V3.73928C4.41004 3.84493 3.54388 4.10421 2.90753 4.74056C2.27118 5.37691 2.0119 6.24307 1.90625 7.56246H20.0942C19.9886 6.24307 19.7293 5.37691 19.093 4.74056C18.4566 4.10421 17.5905 3.84493 16.2711 3.73928V2.29163C16.2711 1.91193 15.9633 1.60413 15.5836 1.60413C15.2039 1.60413 14.8961 1.91193 14.8961 2.29163V3.67845C14.2862 3.66663 13.6027 3.66663 12.8336 3.66663H9.16693C8.39782 3.66663 7.71426 3.66663 7.10443 3.67845V2.29163Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.83203 11C1.83203 10.2309 1.83203 9.54736 1.84386 8.9375H20.1535C20.1654 9.54736 20.1654 10.2309 20.1654 11V12.8333C20.1654 16.2903 20.1654 18.0188 19.0914 19.0927C18.0175 20.1667 16.289 20.1667 12.832 20.1667H9.16536C5.70839 20.1667 3.97992 20.1667 2.90597 19.0927C1.83203 18.0188 1.83203 16.2903 1.83203 12.8333V11ZM15.582 12.8333C16.0883 12.8333 16.4987 12.4229 16.4987 11.9167C16.4987 11.4104 16.0883 11 15.582 11C15.0758 11 14.6654 11.4104 14.6654 11.9167C14.6654 12.4229 15.0758 12.8333 15.582 12.8333ZM15.582 16.5C16.0883 16.5 16.4987 16.0896 16.4987 15.5833C16.4987 15.0771 16.0883 14.6667 15.582 14.6667C15.0758 14.6667 14.6654 15.0771 14.6654 15.5833C14.6654 16.0896 15.0758 16.5 15.582 16.5ZM11.9154 11.9167C11.9154 12.4229 11.505 12.8333 10.9987 12.8333C10.4924 12.8333 10.082 12.4229 10.082 11.9167C10.082 11.4104 10.4924 11 10.9987 11C11.505 11 11.9154 11.4104 11.9154 11.9167ZM11.9154 15.5833C11.9154 16.0896 11.505 16.5 10.9987 16.5C10.4924 16.5 10.082 16.0896 10.082 15.5833C10.082 15.0771 10.4924 14.6667 10.9987 14.6667C11.505 14.6667 11.9154 15.0771 11.9154 15.5833ZM6.41536 12.8333C6.92162 12.8333 7.33203 12.4229 7.33203 11.9167C7.33203 11.4104 6.92162 11 6.41536 11C5.90911 11 5.4987 11.4104 5.4987 11.9167C5.4987 12.4229 5.90911 12.8333 6.41536 12.8333ZM6.41536 16.5C6.92162 16.5 7.33203 16.0896 7.33203 15.5833C7.33203 15.0771 6.92162 14.6667 6.41536 14.6667C5.90911 14.6667 5.4987 15.0771 5.4987 15.5833C5.4987 16.0896 5.90911 16.5 6.41536 16.5Z"
      fill={props.color}
    />
  </svg>
);
export default MeetingsIcon;
