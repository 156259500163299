import { ResponsiveChoropleth } from "@nivo/geo";
import { geoFeatures } from "../../utils/mockGeoFeatures";
//import {mockGeographyData as data} from "../../utils/DummyData";

const GeographyChart = ({
  isDashboard = false,
  data = [],
  maxNumber = 0,
  isMobile = false,
}) => {
  return (
    <ResponsiveChoropleth
      data={data}
      features={geoFeatures.features}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      domain={[0, maxNumber]}
      unknownColor="#e6e6e6"
      label="properties.name"
      //valueFormat=".1s"
      colors="blues"
      projectionScale={isMobile ? 52 : 75}
      projectionTranslation={isDashboard ? [0.5, 0.65] : [0.5, 0.5]}
      projectionRotation={[0, 0, 0]}
      borderWidth={0.4}
      borderColor="#c4c4c4"
    />
  );
};

export default GeographyChart;
