import React from "react";

const Toggle = ({ onChange, active, className }) => {
  return (
    <label className={`flex items-center ${className}`}>
      <input
        type="checkbox"
        checked={active}
        onChange={onChange}
        className="h-4 w-4 mr-2 cursor-pointer"
      />
    </label>
  );
};

export default Toggle;

// import React from "react";

// const Toggle = ({ onChange, checked, className }) => {
//   return (
//     <label className={`flex items-center cursor-pointer ${className}`}>
//       <input
//         type="checkbox"
//         checked={checked}
//         onChange={onChange}
//         className="hidden"
//       />
//       <span
//         className={`w-4 h-4 border-2 rounded ${checked ? "bg-blue-500 border-blue-500" : "border-gray-300"} relative transition duration-300`}
//       >
//         {checked && (
//           <span className="absolute inset-0 bg-white w-2 h-2 m-auto rounded transform scale-75"></span>
//         )}
//       </span>
//     </label>
//   );
// };

// export default Toggle;
