import React, { useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import AnalogTimePicker from "../AnalogTimePicker";

const TimeDropDown = ({
  placeholder,
  selectedText,
  options,
  setSelectedText,
  height,
  dropDownClassName,
  className,
  touched,
  errors,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`md:hidden relative w-[64px] select-none ${className}`}>
      {/* <div
        className={`hidden relative border-b-2 font-thin border-border w-full px-1 md:px-5 caret-slate-600 transition-all duration-500 ease-in-out focus:border-secoundry`}
      >
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={`flex flex-row justify-between ${height} h-[36px] items-center cursor-pointer`}
        >
          <h2
            className={`text-sm font-normal  ${
              selectedText === "" ? "text-appgray" : " text-primary"
            }`}
          >
            {selectedText === "" ? placeholder : selectedText}
          </h2>
          <MdOutlineKeyboardArrowDown
            className={` text-appgray text-xl transition-all duration-300 ease-in-out ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>
        {isOpen && (
          <div
            className={`z-20 bg-white shadow-dropDownBox rounded-md absolute ${dropDownClassName} top-[3rem] right-0`}
          >
            {options?.map((item, index) => (
              <div
                className={`${
                  item === selectedText
                    ? " bg-blue-100 text-primary"
                    : "bg-white"
                } hover:bg-blue-100 hover:text-primary  px-2 py-2 transition-all duration-200 ease-in-out`}
                key={index}
                onClick={() => {
                  setSelectedText(item);
                  setIsOpen(false);
                }}
              >
                {item}
              </div>
            ))}
          </div>
        )}
      </div> */}

      <div
        className={`relative border-b-2 font-thin border-border w-full px-1 md:px-5 caret-slate-600 transition-all duration-500 ease-in-out focus:border-secoundry`}
      >
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={`flex flex-row justify-between ${height} h-[36px] items-center cursor-pointer`}
        >
          <h2
            className={`text-sm font-normal  ${
              selectedText === "" ? "text-appgray" : " text-primary"
            }`}
          >
            {selectedText === "" ? placeholder : selectedText}
          </h2>
          <MdOutlineKeyboardArrowDown
            className={` text-appgray text-xl transition-all duration-300 ease-in-out ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
          />
        </div>
        {isOpen && (
          <div
            className={`z-20 bg-white shadow-dropDownBox rounded-md absolute ${dropDownClassName} top-[3rem]`}
          >
            <AnalogTimePicker
              open={isOpen}
              setOpen={setIsOpen}
              setSelectedText={setSelectedText}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeDropDown;
