import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthRoutes from "./routes/AuthRoutes";
import Layouts from "./layouts/Layouts";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setPakaData } from "./redux/ContentSlice";
import { Puff } from "react-loader-spinner";
import CustomModal from "./components/CustomModal";
// import ReactGA from "react-ga4";
// import TagManager from "react-gtm-module";
// const GA4_ID = process.env.REACT_APP_GA4_ID;
// const GTM_ID = process.env.REACT_APP_GTM_ID;

const App = () => {
  // ReactGA.initialize(GA4_ID);
  // ReactGA.send({ hitType: "pageview", page: "/" });
  // const tagManagerArgs = {
  //   gtmId: GTM_ID,
  // };
  // TagManager.initialize(tagManagerArgs);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const paths = [
    "/",
    "/reset-password",
    "/register/otp",
    "/register",
    "/check",
  ];
  const newUserPaths = ["/", "/reset-password", "/register", "/subscription"];
  const registeredUserPaths = [
    "/dashboard",
    "/bots",
    "/call-history",
    "/message-center",
    "/contacts",
    "/meetings",
    "/support-bot",
    "/settings",
    "/subscription",
  ];

  const [loading, setLoading] = useState(true);

  const getContent = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/get_content`
      );
      dispatch(setPakaData(response.data.contentData));
    } catch (error) {
      console.error("Error fetching content data:", error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or error
    }
  };

  useEffect(() => {
    getContent();
  }, []); // Empty dependency array to run the effect only once on component mount

  useEffect(() => {
    if (
      sessionStorage.getItem("token") !== null &&
      registeredUserPaths.includes(location.pathname)
    ) {
      navigate(location.pathname);
    } else if (
      sessionStorage.getItem("token") === null &&
      newUserPaths.includes(location.pathname)
    ) {
      navigate(location.pathname);
    } else {
      navigate("/");
    }
  }, []); // Run the effect whenever the location pathname changes

  // const initializeGA = () => {
  //   window.dataLayer = window.dataLayer || [];
  //   function gtag() {
  //     window.dataLayer.push(arguments);
  //   }
  //   gtag('js', new Date());
  //   gtag('config', process.env.REACT_APP_GTA);
  // };

  // useEffect(() => {
  //   initializeGA();
  // }, []);

  return (
    <div className="font-Poppins">
      {loading ? (
        // Handle loading state
        <CustomModal isOpen={loading}>
          <div className=" flex items-center justify-center">
            <div className=" bg-white p-8 h-fit w-fit rounded-md">
              <Puff
                height="100"
                width="100"
                radius={1}
                color="#FF0066"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </div>
        </CustomModal>
      ) : paths.includes(location.pathname) ? (
        <AuthRoutes />
      ) : (
        <Layouts />
      )}
    </div>
  );
};

export default App;
