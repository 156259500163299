import { useEffect } from "react";

export function PaddleLoader() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.paddle.com/paddle/paddle.js";
    script.async = true;
    script.onload = () => {
      // Access the Paddle object once the script is loaded
      const Paddle = window.Paddle;
      Paddle.Setup({
        vendor: Number(process.env.REACT_APP_PADDLE_VENDOR_ID),
      });
    };
    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  return null; // or you can return a loading indicator if needed
}
