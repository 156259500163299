import React, { useState } from "react";
import DescriptionInput from "../components/supportBot/DescriptionInput";
import CustomModal from "../components/CustomModal";
import { Puff, ThreeCircles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import SupportBotTable from "../components/supportBot/SupportBotTable";
import Search from "../components/supportBot/Search";
import { useFormik } from "formik";
import { supportBotDataSchema } from "../utils/schemas";
import { TbCloudUpload } from "react-icons/tb";
import PlusIcon from "../assets/images/Icons/PlusIcon";
import { setSupportBot } from "../redux/AppSlice";
import { CgClose } from "react-icons/cg";
import InputFieldM from "../components/meetings/InputFieldM";
import api from "../services/axiosInstance";
import DropZone from "../components/supportBot/DropZone";
import { showToast } from "../utils/showToast";

const SupportBot = () => {
  const pakaData = useSelector(
    (state) => state.content.pakaData
  )?.supportBotContent;
  const userData = useSelector((state) => state.app.userData);
  const supportBotList = useSelector((state) => state.app.supportBot);
  const contactSearch = useSelector((state) => state.contact.contactSearch);
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Create New");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedSupportBots, setSelectedSupportBots] = useState([]);
  const [createSupportBotData, setCreateSupportBotData] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    // setTouched,
    // setFieldError,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: supportBotDataSchema,
    initialValues: {
      user_id:
        createSupportBotData != null
          ? createSupportBotData.user_id
          : userData?.id || "",
      topic: createSupportBotData != null ? createSupportBotData.topic : "",
      description:
        createSupportBotData != null ? createSupportBotData.description : "",
      knowledge:
        createSupportBotData != null ? createSupportBotData.knowledge : "",
      file: createSupportBotData != null ? createSupportBotData.file : "",
      knowledgeInFile:
        createSupportBotData != null
          ? createSupportBotData.knowledgeInFile
          : {
              fileName: "",
              knowledge: "",
            },
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (selectedOption === "Create New") {
        createSupportBot(values, resetForm);
      } else if (selectedOption === "Edit") {
        values._id = createSupportBotData?._id || "";
        updateSupportBot(values);
        setSelectedSupportBots([]);
      }
    },
  });

  // Search the contact
  const keys = ["topic", "knowledge", "description"];
  const search = (data) => {
    return data?.filter((item) =>
      keys.some((key) => {
        return item[key].match(
          new RegExp(contactSearch.replace(/[+*(){}?\\[\]]/g, "\\$&"), "i")
        );
      })
    );
  };

  // handle input file in support bot creation
  // const [file, setFile] = useState(null);
  const handleFileClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    // setFile(event.target.files[0]);
    setFieldValue("file", event.target.files[0]);
  };
  const handleDrop = (files) => {
    console.log(files);
    setFieldValue("file", files[0]);
  };

  // API call for create topic
  const createSupportBot = async (values, resetForm) => {
    const formData = new FormData();
    formData.append("file", values?.file);
    formData.append("user_id", values?.user_id);
    formData.append("topic", values?.topic);
    formData.append("description", values?.description);
    formData.append("knowledge", values?.knowledge);
    setLoader(true);
    api
      .post(`/create_support_bot`, formData)
      .then(async ({ data }) => {
        resetForm();
        // setFile(null);
        setFieldValue("file", null);
        setShowModel(false);
        dispatch(setSupportBot(data.supportBotList));
        setLoader(false);
        showToast("success", "Support bot Created Successfully", {
          style: { backgroundColor: "green" },
          toastId: "createSupportBot",
          containerId: "SupportBot",
        });
      })
      .catch((error) => {
        setShowModel(false);
        setLoader(false);
        showToast(
          "error",  
          error.response.data.message || "Something went wrong!!",
          {
            style: { backgroundColor: "red" },
            toastId: "createSupportBot",
            containerId: "SupportBot",
          }
        )
        console.log(error, "create Support bot error");
      });
  };

  const deleteSupportBot = async (supportBotData) => {
    setDeleteLoader(true);
    // console.log(supportBotData);
    api
      .post(`/delete_support_bots`, {
        user_id: userData?.id || "",
        _ids: supportBotData,
      })
      .then(async ({ data }) => {
        setDeleteLoader(false);
        dispatch(setSupportBot(data.supportBotList));
        setSelectedSupportBots([]);
        setShowModel(false);
        setSelectAll(false);
        showToast("success", "Support bot Deleted Successfully", {
          style: { backgroundColor: "green" },
          toastId: "deleteSupportBot",
          containerId: "SupportBot",
        });
      })
      .catch((error) => {
        setDeleteLoader(false);
        setShowModel(false);
        setSelectAll(false);
        showToast(
          "error",
          error.response.data.message || "Something went wrong!!",
          {
            style: { backgroundColor: "red" },
            toastId: "deleteSupportBot",
            containerId: "SupportBot",
          }
        );
        console.log(error, "delete support bot error");
      });
  };

  const updateSupportBot = async (values) => {
    const formData = new FormData();
    formData.append("file", values?.file);
    formData.append("_id", values?._id);
    formData.append("user_id", values?.user_id);
    formData.append("topic", values?.topic);
    formData.append("description", values?.description);
    formData.append("knowledge", values?.knowledge);
    formData.append("fileKnowledge", values?.knowledgeInFile?.knowledge);
    formData.append("fileName", values?.knowledgeInFile?.fileName);
    setLoader(true);

    api
      .post(`/update_support_bot`, formData)
      .then(async ({ data }) => {
        setLoader(false);
        dispatch(setSupportBot(data?.supportBotList || []));
        // setFile(null);
        setFieldValue("file", null);
        setSelectedSupportBots([]);
        setShowModel(false);
       
        showToast("success", "Support bot Updated Successfully", {
          style: { backgroundColor: "green" },
          toastId: "updateSupportBot",
          containerId: "SupportBot",
        });
      })
      .catch((error) => {
        setShowModel(false);
        setLoader(false);
        showToast(
          "error",
          error.response.data.message || "Something went wrong!!",
          {
            style: { backgroundColor: "red" },
            toastId: "updateSupportBot",
            containerId: "SupportBot",
          }
        );
        console.log(error, "update error");
      });
  };

  return (
    <div className=" px-3 relative">
      <CustomModal isOpen={loader}>
        <div className=" flex items-center justify-center">
          <div className=" bg-white p-8 h-fit w-fit rounded-md">
            <Puff
              height="100"
              width="100"
              radius={1}
              color="#FF0066"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      </CustomModal>
      <ToastContainer containerId="SupportBot" />
      <CustomModal
        isOpen={showModel}
        className={` lg:w-8/12 lg:ml-[20%] -mt-12 lg:mt-0`}
      >
        {selectedOption === "Delete" && (
          <div className=" flex flex-col rounded-md items-center bg-white w-full mx-3 lg:mx-0 md:w-1/2 h-fit mt-36 lg:mt-12 py-6">
            <img
              className=" w-12 h-12"
              alt="del"
              src={require("../assets/images/trash.png")}
            />
            {deleteLoader ? (
              <div className="  my-5  w-full flex flex-col items-center">
                <ThreeCircles
                  height="60"
                  width="60"
                  color="#000032"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                />
                <p className=" mt-5 text-primary">
                  Deleting the Support Bot.....
                </p>
              </div>
            ) : (
              <div className=" w-full flex flex-col items-center">
                <p className=" text-appgray text-[18px] mx-3 lg:mx-0 lg:w-8/12 flex text-center">
                  Deleting a Support Bot will delete it permanently, are you
                  sure?
                </p>
                <button
                  onClick={() => deleteSupportBot(selectedSupportBots)}
                  className={` flex justify-center w-1/2 lg:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
                >
                  Yes, Delete
                </button>
                <button
                  onClick={() => {
                    setShowModel(false);
                    setSelectedSupportBots([]);
                  }}
                  className=" mt-3"
                >
                  No, Keep it
                </button>
              </div>
            )}
          </div>
        )}
        {selectedOption === "Create New" && (
          <div className="rounded-2xl bg-white w-[90%] lg:max-w-[50%] lg:min-w-[620px] mx-3 lg:mx-0 md:w-8/12 mt-24 lg:mt-8 py-6 h-fit">
            <div className=" w-full flex items-center justify-between mx-4">
              <p className="text-lg text-primary font-semibold mb-2">
                Create a new support bot
              </p>
              <CgClose
                onClick={() => {
                  setShowModel(false);
                }}
                className="text-2xl cursor-pointer mr-8 mb-2"
              />
            </div>
            <hr />

            <div className=" mx-[2%] mt-3 flex flex-col items-center md:items-start gap-3">
              <div className="w-[95%] lg:w-full">
                <InputFieldM
                  onChange={handleChange("topic")}
                  onBlur={handleBlur("topic")}
                  title="Topic*"
                  value={values?.topic}
                  placeholder="eg. Demo Paka AI"
                  info={pakaData?.topic}
                  touched={touched.topic}
                  errors={errors.topic}
                />
                <div className=" h-3 pt-1  ">
                  {touched.topic && errors.topic && (
                    <p className=" text-[11px] text-red-500">{errors.topic}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="w-[95%] lg:w-full mt-5">
              <DescriptionInput
                title="Description*"
                onChange={handleChange("description")}
                onBlur={handleBlur("description")}
                value={values?.description}
                placeholder="An expert support bot for all types of cell phones that are available for purchase at the GS Electronics store. Can help and give information on prices, parameters and can compare all types of phones."
                info={pakaData?.description}
                className={`mx-4`}
              />
              <div className="-mt-2 h-3 ml-3 ">
                {touched.description && errors.description && (
                  <p className=" text-[11px] text-red-500">
                    {errors.description}
                  </p>
                )}
              </div>
            </div>
            <div className="w-[95%] lg:w-full mt-8">
              <DescriptionInput
                title="knowledge"
                onChange={handleChange("knowledge")}
                onBlur={handleBlur("knowledge")}
                value={values?.knowledge}
                placeholder="Knowledge of the bot"
                info={pakaData?.knowledge}
                className={`mx-4`}
              />
              <div className="-mt-2 h-3 ml-3 ">
                {touched.knowledge && errors.knowledge && (
                  <p className=" text-[11px] text-red-500">
                    {errors.knowledge}
                  </p>
                )}
              </div>
            </div>
            <div className="w-[90%] bg-[#F8F8F8] mx-5 rounded-xl lg:w-[95%] mt-5 border-dashed border-2 border-primary">
              <DropZone onDrop={handleDrop}>
                <div
                  className="flex justify-center my-2 cursor-pointer"
                  onClick={handleFileClick}
                >
                  <TbCloudUpload className=" text-secoundry text-4xl" />
                </div>
                <div className="text-center">
                  <span className=" font-semibold">Click to Upload </span>
                  <span>or Drag and Drop</span>
                  <p className="mb-2">( .txt, .docx Max File size: 25 MB)</p>
                  {values?.file && (
                    <div className="flex items-center justify-center gap-5 mb-2">
                      <p className="text-sm text-gray-600 font-semibold">
                        {values?.file?.name}
                      </p>
                      <CgClose
                        onClick={() => {
                          // setFile(null);
                          setFieldValue("file", null);
                        }}
                        title={"remove selected file"}
                        className=" text-secoundry font-medium text-md cursor-pointer"
                      />
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </DropZone>
            </div>

            <div className="w-full flex justify-center items-center mt-3 text-center">
              <button
                type="submit"
                onClick={handleSubmit}
                className={` flex justify-center w-8/12 lg:w-1/2 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
              >
                Create Bot
              </button>
              <button
                onClick={() => {
                  setShowModel(false);
                }}
                className="w-8/12 lg:w-1/2 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry py-2 px-3 rounded-md mx-3 text-primary border border-black"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {selectedOption === "Edit" && (
          <div className="rounded-2xl bg-white w-[90%] lg:max-w-[50%] lg:min-w-[620px] mx-3 lg:mx-0 md:w-8/12 mt-24 lg:mt-8 py-6 h-fit">
            <div className="w-full flex items-center justify-between mx-4">
              <p className="text-lg text-primary font-bold mb-4">
                Update Support Bot
              </p>
              <CgClose
                onClick={() => {
                  setShowModel(false);
                  setSelectedSupportBots([]);
                }}
                className="text-2xl cursor-pointer mr-8 mb-2"
              />
            </div>
            <hr />

            <div className=" mx-[2%] mt-3 flex flex-col items-center md:items-start gap-3">
              <div className="w-[95%] lg:w-full">
                <InputFieldM
                  onChange={handleChange("topic")}
                  onBlur={handleBlur("topic")}
                  title="Topic*"
                  value={values?.topic}
                  placeholder="eg. Demo Paka AI"
                  info={pakaData?.topic}
                  touched={touched.topic}
                  errors={errors.topic}
                />
                <div className=" h-3 pt-1  ">
                  {touched.topic && errors.topic && (
                    <p className=" text-[11px] text-red-500">{errors.topic}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="w-[95%] lg:w-full mt-6">
              <DescriptionInput
                title="Description*"
                onChange={handleChange("description")}
                onBlur={handleBlur("description")}
                value={values?.description}
                placeholder="An expert support bot for all types of cell phones that are available for purchase at the GS Electronics store. Can help and give information on prices, parameters and can compare all types of phones."
                info={pakaData?.description}
                className={`mx-4`}
              />
              <div className=" h-3 pt-1 ml-3 ">
                {touched.description && errors.description && (
                  <p className=" text-[11px] text-red-500">
                    {errors.description}
                  </p>
                )}
              </div>
            </div>
            <div className="w-[95%] lg:w-full mt-5">
              <DescriptionInput
                title="Knowledge"
                onChange={handleChange("knowledge")}
                onBlur={handleBlur("knowledge")}
                value={values?.knowledge}
                placeholder="Knowledge of the bot"
                info={pakaData?.knowledge}
                className={`mx-4`}
              />
              <div className=" h-3 pt-1 ml-3 ">
                {touched.knowledge && errors.knowledge && (
                  <p className=" text-[11px] text-red-500">
                    {errors.knowledge}
                  </p>
                )}
              </div>
            </div>
            <div className="w-[90%] bg-[#F8F8F8] mx-5 rounded-xl lg:w-[95%] mt-5 border-dashed border-2 border-primary">
              <DropZone onDrop={handleDrop}>
                <div
                  className="flex justify-center my-2 cursor-pointer"
                  onClick={handleFileClick}
                >
                  <TbCloudUpload className=" text-secoundry text-4xl" />
                </div>
                <div className="text-center">
                  <span className=" font-semibold">Click to Upload </span>
                  <span>or Drag and Drop</span>
                  <p className="mb-2">( .txt, .docx Max File size: 25 MB)</p>
                  {values?.file && (
                    <div className="flex items-center justify-center gap-5 mb-2">
                      <p className="text-sm text-gray-600 font-semibold">
                        {values?.file.name}
                      </p>
                      <CgClose
                        onClick={() => {
                          // setFile(null);
                          setFieldValue("file", null);
                        }}
                        title={"remove selected file"}
                        className=" text-secoundry font-medium text-md cursor-pointer"
                      />
                    </div>
                  )}
                  {!values?.file && values?.knowledgeInFile?.fileName && (
                    <div className="flex items-center justify-center gap-5 mb-2">
                      <p
                        className="text-sm text-gray-600 font-semibold"
                        title={values?.knowledgeInFile?.knowledge}
                      >
                        {values?.knowledgeInFile?.fileName}
                      </p>

                      <CgClose
                        onClick={() => {
                          setFieldValue("knowledgeInFile", {
                            fileName: "",
                            knowledge: "",
                          });
                        }}
                        title={"remove file"}
                        className=" text-secoundry font-medium text-md cursor-pointer"
                      />
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </DropZone>
            </div>

            <div className="w-full flex justify-center items-center mt-3 text-center">
              <button
                type="submit"
                onClick={handleSubmit}
                className={` flex justify-center w-8/12 lg:w-1/2 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
              >
                Save
              </button>
              <button
                onClick={() => {
                  setShowModel(false);
                  setFieldValue("file", null);
                  setSelectedSupportBots([]);
                }}
                className="w-8/12 lg:w-1/2 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry py-2 px-3 rounded-md mx-3 text-primary border border-black"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </CustomModal>
      <h1 className=" mt-6 lg:mt-0 text-2xl font-bold text-primary ">
        Support bot
      </h1>
      <div className="hidden md:flex justify-between items-center">
        <p className=" text-[11px] text-appgray">
          {/* {pakaData?.meetings?.subHeading} */}A bot with comprehensive
          information in a certain field. Powered by the main bot based on its
          description.
        </p>

        <button
          onClick={() => {
            setShowModel(true);
            setCreateSupportBotData();
            setSelectedOption("Create New");
          }}
          className=" flex items-center bg-primary cursor-pointer  px-3 py-2 rounded-md mx-3"
        >
          <PlusIcon color={"#fff"} />
          <p className=" text-white ml-2 text-[14px] font-semibold">
            Create a new support bot
          </p>
        </button>
      </div>
      <div className="md:hidden block items-center">
        <p className=" text-[11px] text-appgray">
          {/* {pakaData?.meetings?.subHeading} */}A bot with comprehensive
          information in a certain field. Powered by the main bot based on its
          description.
        </p>
        <div className="flex justify-between mt-2">
          <button
            onClick={() => {
              setShowModel(true);
              setCreateSupportBotData();
              setSelectedOption("Create New");
            }}
            className=" w-full flex gap-2 justify-center items-center bg-primary cursor-pointer px-2 py-2 rounded-md mx-2"
          >
            <PlusIcon color={"#fff"} />
            <p className="text-white mx-1 text-[14px] font-medium">
              Create a new support bot
            </p>
          </button>
        </div>
        <div className="md:hidden block mx-2 mt-2">
          <Search className={"text-white "} />
        </div>
      </div>
      <div className="static mt-4 rounded-2xl overflow-hidden shadow-tableShadow bg-white mb-12 lg:mb-0 ">
        <div className=" bg-primary flex justify-start items-center px-3 gap-x-4 py-2 h-10 md:h-14 ">
          {selectedSupportBots.length > 0 ? (
            <div className="flex text-white gap-5 font-medium">
              <button
                onClick={() => {
                  setShowModel(true);
                  setSelectedOption("Delete");
                }}
                className="md:border-white text-center bg-primary border-2 border-primary py-1.5 px-8 hover:bg-secoundry transition-all ease-in-out duration-300 rounded-md mx-3"
              >
                delete
              </button>
              <p className="mt-2 font-medium">
                - {selectedSupportBots.length} {"were chosen"}
              </p>
            </div>
          ) : (
            ""
          )}
          <div className="hidden md:block w-[75%]">
            <Search className={"text-white "} />
          </div>
        </div>

        <SupportBotTable
          listOfSupportBots={search(supportBotList)}
          setSelectedOption={(opt) => {
            setSelectedOption(opt);
          }}
          setShowModel={setShowModel}
          selectedSupportBots={selectedSupportBots}
          setSelectedSupportBots={setSelectedSupportBots}
          setCreateSupportBotData={setCreateSupportBotData}
          selectAll={selectAll}
          setSelectAll={setSelectAll}
        />
      </div>
    </div>
  );
};

export default SupportBot;
