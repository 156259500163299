import * as yup from "yup";

export const registerSchema = yup.object().shape({
  firstname: yup
    .string("only string is allowed")
    .max(64, "FirstName cannot be more than 64 characters")
    .required("FirstName is required"),
  lastname: yup
    .string("only string is allowed")
    .max(64, "LastName cannot be more than 64 characters")
    .required("LastName is required"),
  email: yup
    .string()
    .email("Enter Valid Email")
    .max(128, "Email cannot be more than 128 characters")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, ({ min }) => `Password must be at least ${min} characters`)
    .max(128, "Password cannot be more than 128 characters")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])/,
      "Password must contain at least one special character and one number"
    )
    .required("Password is required"),
  rePassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
});

export const botIdentitySchema = yup.object().shape({
  botName: yup.string("only string is allowed").required("BotName is required"),
  language: yup
    .string("only string is allowed")
    .required("Language is required"),
  voiceType: yup
    .string("only string is allowed")
    .required("vice type is required"),
  speed: yup.number("Only Number Allowed").required("pitch is required"),
  pitch: yup.number("Only Number Allowed").required("pitch is required"),
});

export const botGreetingSchema = yup.object().shape({
  botGreetings: yup.array(
    yup.object().shape({
      greetText: yup.string("only string is allowed").required("Required"),
    })
  ),
});

export const yourBusinessSchema = yup.object().shape({
  businessName: yup
    .string("only string is allowed")
    .required("Business Name is required"),
  businessDesc: yup
    .string("only string is allowed")
    .required("Business Description is required"),
  countryISO: yup
    .string("only string is allowed")
    .required("Country ISO is required"),
  businessOperationStatus: yup.boolean().required("Required"),
  totalOperations: yup.array(
    yup.object().shape({
      operationType: yup.string("only string is allowed"),
      operation_id: yup.string("only string is allowed"),
    })
  ),
  onlyTakeMessages: yup.boolean().required("Required"),
  totalBranches: yup
    .array()
    .when(["onlyTakeMessages"], (onlyTakeMessages, schema) => {
      return onlyTakeMessages
        ? schema
        : schema.of(
            yup.object().shape({
              branchName: yup
                .string("only string is allowed")
                .required("Required"),
              description: yup
                .string("only string is allowed")
                .required("Required"),
              contact: yup
                .string("only string is allowed")
                .required("Required"),
            })
          );
    }),
});

export const contactDataSchema = yup.object().shape({
  firstName: yup
    .string("only string is allowed")
    .required("First Name is required"),
  lastName: yup.string("only string is allowed"),
  email: yup.string().email("Invalid email"),
});

export const meetingDataSchema = yup.object().shape({
  topic: yup.string("only string is allowed").required("Topic is required"),
  duration: yup
    .string("only string is allowed")
    .required("Duration is required"),
  breakTime: yup
    .string("only string is allowed")
    .required("Break Time is required"),
  location: yup.object().shape({
    type: yup
      .string("only string is allowed")
      .required("Break Time is required"),
    address: yup.string().when("type", {
      is: (value) => value === "Frontal",
      then: (schema) =>
        schema.required("Address is required for Frontal location"),
      otherwise: (schema) => schema,
    }),
  }),
  description: yup
    .string("only string is allowed")
    .required("Description is required"),
  // availability: yup.array(
  //   yup
  //     .object()
  //     .shape({
  //       day: yup.string("only string is allowed").required("Required"),
  //       active: yup
  //         .boolean("Only true or false")
  //         .required("Required")
  //         .default(false),
  //       meetingTime: yup.array(
  //         yup
  //           .object()
  //           .shape({
  //             from: yup
  //               .string("only string is allowed")
  //               .required("Required")
  //               .default("09:30"),
  //             to: yup
  //               .string("only string is allowed")
  //               .required("Required")
  //               .default("17:30"),
  //           })
  //           .test(
  //             "isValidTimeRange",
  //             "From time must be less than To time",
  //             function (value) {
  //               const { from, to } = value;
  //               const { active } = this.options.context;
  //               if (active) {
  //                 return from < to;
  //               }
  //               return true;
  //             }
  //           )
  //       ),
  //     })
  //     .test("activeTimeRange", null, function (value) {
  //       const { active, meetingTime } = value;
  //       if (active) {
  //         for (let i = 0; i < meetingTime.length; i++) {
  //           const { from, to } = meetingTime[i];
  //           if (from >= to) {
  //             return this.createError({
  //               path: `availability[${this.options.index}].meetingTime[${i}]`,
  //               message: "From time must be less than To time",
  //             });
  //           }
  //         }
  //       }
  //       return true;
  //     })
  // ),
  meetingWindow: yup
    .string("only string is allowed")
    .required("Meeting Window is required"),
});

export const supportBotDataSchema = yup
  .object()
  .shape({
    topic: yup.string("only string is allowed").required("Topic is required"),
    description: yup
      .string("only string is allowed")
      .required("Description is required"),
    knowledge: yup.string("only string is allowed"),
    file: yup.mixed(),
    knowledgeInFile: yup.object().shape({
      fileName: yup.string("only string is allowed"),
      knowledge: yup.string("only string is allowed"),
    }),
  })
  .test("at-least-one", "Either knowledge, file is required", function (value) {
    const { knowledge, file, knowledgeInFile } = value;
    const hasKnowledgeInFile =
      knowledgeInFile &&
      (knowledgeInFile?.fileName || knowledgeInFile?.knowledge);

    if (!knowledge && !file && !hasKnowledgeInFile) {
      return this.createError({
        path: "knowledge",
        message: "Either knowledge, file is required",
      });
    }
    return true;
  });

export const faqSchema = yup.object().shape({
  toggle: yup.boolean("Only true or false").required("required"),
  totalWorkingHours: yup.array(
    yup
      .object()
      .shape({
        day: yup.string("only string is allowed").required("Required"),
        active: yup
          .boolean("Only true or false")
          .required("Required")
          .default(false),
        workingTime: yup.array(
          yup
            .object()
            .shape({
              from: yup
                .string("only string is allowed")
                .required("Required")
                .default("09:30"),
              to: yup
                .string("only string is allowed")
                .required("Required")
                .default("17:30"),
            })
            .test(
              "isValidTimeRange",
              "From time must be less than To time",
              function (value) {
                const { from, to } = value;
                const { active } = this.options.context;
                if (active) {
                  return from < to;
                }
                return true;
              }
            )
        ),
      })
      .test("activeTimeRange", null, function (value) {
        const { active, workingTime } = value;
        if (active) {
          for (let i = 0; i < workingTime.length; i++) {
            const { from, to } = workingTime[i];
            if (from >= to) {
              return this.createError({
                path: `totalWorkingHours[${this.options.index}].workingTime[${i}]`,
                message: "From time must be less than To time",
              });
            }
          }
        }
        return true;
      })
  ),
  businessFAQ: yup.array(
    yup.object().shape({
      question: yup.string("only string is allowed").required("Required"),
      answer: yup.string("only string is allowed").required("Required"),
    })
  ),
  timeZone: yup.object().required("Required"),
});

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .min(8, ({ min }) => ``)
    .required("Old password is required"),
  newPassword: yup
    .string()
    .min(8, ({ min }) => `Password must be at least ${min} characters`)
    .max(128, "Password cannot be more than 128 characters")
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])/,
      "Password must contain at least one special character and one number"
    )
    .required("New password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export const profileSchema = yup.object().shape({
  firstName: yup
    .string("only string is allowed")
    .required("FirstName is required"),
  lastName: yup
    .string("only string is allowed")
    .required("Last Name is required"),
});

export const whatsAppRequestSchema = yup.object().shape({
  description: yup.string("only string is allowed"),
  businessAddress: yup.string("only string is allowed"),
  email: yup.string().email("Invalid email"),
  website: yup.string("only string is allowed"),
  file: yup.mixed(),
});
