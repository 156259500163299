import React from "react";
import { BiSearch } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { setContactSearch } from "../../redux/ContactSlice";

const Search = ({className}) => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="hidden relative p-2 rounded-md w-1/4 md:flex flex-row items-center border border-[#D4D4D4]">
        <BiSearch className=" ml-3 text-appgray" />
        <input
          className=" outline-none bg-transparent w-full pl-2 py-0.8 placeholder:text-[13px] caret-appgray text-white "
          placeholder="Search Contact…"
          onChange={(e) => dispatch(setContactSearch(e.target.value))}
        />
      </div>

      <div className="md:hidden relative p-1 rounded-md w-full flex flex-row items-center border border-[#D4D4D4]">
        <BiSearch className=" ml-3 text-appgray" />
        <input
          className={`outline-none bg-transparent w-full pl-2 py-0.8 placeholder:text-[13px] caret-appgray ${className}`}
          placeholder="Search Contact…"
          onChange={(e) => dispatch(setContactSearch(e.target.value))}
        />
      </div>
    </>
  );
};

export default Search;
