import React, { useEffect, useState } from "react";

const DescriptionInput = ({
  title,
  placeholder,
  onChange,
  onBlur,
  value,
  className,
  readOnly,
  maxLength,
}) => {
  const [charCount, setCharCount] = useState(value?.length);

  useEffect(() => {
    // Update charCount when the component mounts or when the value prop changes
    setCharCount(value?.length);
  }, [value]);

  const handleTextareaChange = (event) => {
    const textareaValue = event.target.value;
    setCharCount(textareaValue?.length);
    onChange(event);
  };

  return (
    <div className={`relative ${className} w-full `}>
      <textarea
        readOnly={readOnly}
        placeholder={placeholder}
        onChange={handleTextareaChange}
        onBlur={onBlur}
        value={value}
        maxLength={maxLength}
        className="outline-none border-2 text-sm placeholder:text-appgray border-border rounded-md py-3 w-full pl-3 caret-slate-600 transition-all duration-500 ease-in-out focus:border-pink-300"
      />
      <h1 className="bg-white absolute -top-2 text-sm px-1 left-3 font-thin text-primary">
        {title}
      </h1>
      {maxLength ? (
        <div className="absolute bottom-4 right-3 text-xs text-gray-500">
          {charCount}/{maxLength}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DescriptionInput;
