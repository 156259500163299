import React, { useState } from "react";
import ContactsIcon from "../assets/images/Icons/ContactsIcon";
import InputField from "../components/InputField";
import DescriptionInput from "../components/DescriptionInput";
import api from "../services/axiosInstance";
import CustomModal from "../components/CustomModal";
import { Puff, ThreeCircles } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { setContactsList } from "../redux/ContactSlice";
import { ToastContainer } from "react-toastify";
import { showToast } from "../utils/showToast";
import ContactsTable from "../components/Contacts/contactsTable";
import Search from "../components/Contacts/Search";
import ImportIcon from "../assets/images/Icons/ImportIcon";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdBlockFlipped } from "react-icons/md";
import { useFormik } from "formik";
import { contactDataSchema } from "../utils/schemas";
import ContactInput from "../components/Contacts/ContactInput";
import { saveAs } from "file-saver";
import CsvIcon from "../assets/images/Icons/CsvIcon";
import { useNavigate } from "react-router-dom";
const contactFormatFilePath = require("../utils/contact-csv-format.xlsx");

// Download xlsx or CSV format
const downloadExistingFile = (fileUrl, fileName) => {
  // Fetch the file as a blob
  fetch(fileUrl)
    .then((response) => response.blob())
    .then((blob) => {
      saveAs(blob, fileName);
    })
    .catch((error) => console.error("Error downloading file:", error));
};

const Contacts = () => {
  const pakaData = useSelector((state) => state.content.pakaData);
  const listOfBots = useSelector((state) => state.app.botsList).filter(
    (bot) => bot.status === "active"
  );
  const userData = useSelector((state) => state.app.userData);
  const ContactsList = useSelector((state) => state.contact.contactsList);
  const contactSearch = useSelector((state) => state.contact.contactSearch);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Create New");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [contactUploadLoader, setContactUploadLoader] = useState(false);
  const [selectedCode, setSelectedCode] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState({
    ISO: "US",
    Country: "United States",
    Country_Code: 1,
    ISO_CODES: "US / USA",
  });
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonOpen, setIsButtonOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [createContactData, setCreateContactData] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    // setTouched,
    // setFieldError,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: contactDataSchema,
    initialValues: {
      user_id:
        createContactData != null
          ? createContactData.user_id
          : userData?.id || "",
      firstName: createContactData != null ? createContactData.firstName : "",
      lastName: createContactData != null ? createContactData.lastName : "",
      phone:
        createContactData != null
          ? createContactData.phone
          : {
              contact: "",
              countryCode: 1,
            },
      email: createContactData != null ? createContactData.email : "",
      bots:
        createContactData != null
          ? createContactData?.bot?.map((bot) => bot.id)
          : listOfBots?.map((contact) => contact._id) || [],
      note: createContactData != null ? createContactData.note : "",
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      if (selectedOption === "Create New") {
        createContact(values, resetForm);
      } else if (selectedOption === "Edit") {
        values._id = createContactData?._id || "";
        updateContact(values);
        setSelectedContacts([]);
      }
    },
  });

  const [selectedBots, setSelectedBots] = useState([]);
  const [allBotsSelected, setAllBotsSelected] = useState(false);

  const contactType = ["All", "Blacklisted"];
  const [selectedContactType, setSelectedContactType] = useState("All");

  // for the file upload
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    // Do what you to do with file
  };
  const handleFileSubmit = async () => {
    setContactUploadLoader(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("user_id", userData?.id);

    try {
      const res = api.post(`/contact/upload_contacts`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(setContactsList(res.data.contactList));
      console.log("File uploaded successfully:", res.data);
      setShowModel(false);
      setContactUploadLoader(false);
      showToast("success", "Contacts Created Successfully", {
        style: { backgroundColor: "green" },
        toastId: "createContacts",
        containerId: "Contacts",
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      setShowModel(false);
      setContactUploadLoader(false);
      showToast(
        "error",
        error.response.data.message ||
          "Something went wrong! Please recheck your xlsx file again!",
        {
          style: { backgroundColor: "red" },
          toastId: "createContacts",
          containerId: "Contacts",
        }
      );
    }
  };

  const handleBotSelection = (botName, botId) => {
    if (selectedOption === "Create New") {
      // Reset state and field value for new contact form
      setAllBotsSelected(false);
      setSelectedBots([]);
      setFieldValue("bots", []);
    }
    let updatedSelectedBots;
    if (botId === "all") {
      // Handle "All" option
      setAllBotsSelected(!allBotsSelected);
      updatedSelectedBots = allBotsSelected
        ? []
        : listOfBots.map((bot) => ({ id: bot._id, name: bot.details.botName }));
    } else {
      // Handle individual bot selection
      const isBotSelected = selectedBots.some((bot) => bot.id === botId);
      if (isBotSelected) {
        // Remove bot from the selected list if already selected
        updatedSelectedBots = selectedBots.filter((bot) => bot.id !== botId);
      } else {
        // Add bot to the selected list if not selected
        updatedSelectedBots = [...selectedBots, { id: botId, name: botName }];
      }
    }

    // Update the state of selectedBots
    setSelectedBots(updatedSelectedBots);

    // Update Formik state with bot IDs only
    const botIds = updatedSelectedBots.map((bot) => bot.id);
    setFieldValue("bots", botIds);
  };

  // Search the contact
  const keys = [
    "firstName",
    "lastName",
    "phone",
    "note",
    "email",
    "phone_number",
  ];
  const search = (data) => {
    return data.filter((item) =>
      keys.some((key) => {
        if (key === "phone") {
          return item[key]?.contact?.match(
            new RegExp(contactSearch.replace(/[+*(){}?\\[\]]/g, "\\$&"), "i")
          );
        }
        return item[key].match(
          new RegExp(contactSearch.replace(/[+*(){}?\\[\]]/g, "\\$&"), "i")
        );
      })
    );
  };

  // for xlsx download

  const handleDownload = () => {
    downloadExistingFile(contactFormatFilePath, "contact-csv-format.xlsx");
  };

  // API call for create contact
  const createContact = async (contactData, resetForm) => {
    setLoader(true);
    api
      .post(`/contact/create_contact`, contactData)
      .then(async ({ data }) => {
        resetForm();
        setSelectedCode(1);
        setShowModel(false);
        setLoader(false);
        dispatch(setContactsList(data.contactList));
        showToast("success", "Contact Created Successfully", {
          style: { backgroundColor: "green" },
          toastId: "createContact",
          containerId: "Contacts",
        });
      })
      .catch((error) => {
        setShowModel(false);
        setLoader(false);
        showToast(
          "error",
          error.response.data.message || "Something went wrong!!",
          {
            style: { backgroundColor: "red" },
            toastId: "createContact",
            containerId: "Contacts",
          }
        );
        console.log(error, "create Contact error");
      });
  };

  const deleteContacts = async (contactData) => {
    setDeleteLoader(true);
    // console.log(contactData);
    api
      .post(`/contact/delete_contacts`, {
        user_id: userData?.id || "",
        _ids: contactData,
      })
      .then(async ({ data }) => {
        setDeleteLoader(false);
        dispatch(setContactsList(data.contactList));
        setSelectedContacts([]);
        console.log(data, "data after delete contact");
        setShowModel(false);
        setSelectAll(false);
        showToast("success", "Contact Deleted Successfully", {
          style: { backgroundColor: "green" },
          toastId: "deleteContact",
          containerId: "Contacts",
        });
      })
      .catch((error) => {
        setDeleteLoader(false);
        setShowModel(false);
        setSelectAll(false);
        showToast(
          "error",
          error.response.data.message || "Something went wrong!!",
          {
            style: { backgroundColor: "red" },
            toastId: "deleteContact",
            containerId: "Contacts",
          }
        );
        console.log(error, "delete Contacts error");
      });
  };

  const updateContact = async (contactData) => {
    // contactData.user_id = userData._id;
    setLoader(false);
    // console.log(contactData);
    api
      .post(`/contact/update_contact`, contactData)
      .then(async ({ data }) => {
        setLoader(false);
        dispatch(setContactsList(data.contactList));
        setSelectedContacts([]);
        setShowModel(false);
        showToast("success", "Contact Updated Successfully", {
          style: { backgroundColor: "green" },
          toastId: "updateContact",
          containerId: "Contacts",
        });
      })
      .catch((error) => {
        showToast(
          "error",
          error.response.data.message || "Something went wrong!!",
          {
            style: { backgroundColor: "red" },
            toastId: "updateContact",
            containerId: "Contacts",
          }
        );
        console.log(error, "update error");
      });
  };

  return (
    <div className=" px-3 relative">
      <CustomModal isOpen={loader}>
        <div className=" flex items-center justify-center">
          <div className=" bg-white p-8 h-fit w-fit rounded-md">
            <Puff
              height="100"
              width="100"
              radius={1}
              color="#FF0066"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      </CustomModal>
      <ToastContainer containerId="Contacts" />
      <CustomModal
        isOpen={showModel}
        className={` lg:w-8/12 lg:ml-[20%] -mt-12 lg:mt-0`}
      >
        {selectedOption === "Delete" && (
          <div className=" flex flex-col rounded-md items-center bg-white w-full  mx-3 lg:mx-0 md:w-1/2 h-fit mt-32 lg:mt-12 py-6">
            <img
              className=" w-12 h-12"
              alt="del"
              src={require("../assets/images/trash.png")}
            />
            {deleteLoader ? (
              <div className="  my-5  w-full flex flex-col items-center">
                <ThreeCircles
                  height="60"
                  width="60"
                  color="#000032"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                />
                <p className=" mt-5 text-primary">Deleting the Contacts.....</p>
              </div>
            ) : (
              <div className=" w-full flex flex-col items-center">
                <p className=" text-appgray text-[18px] mx-3 lg:mx-0 lg:w-8/12 flex text-center">
                  Deleting a Contact will delete it permanently, are you sure?
                </p>
                <button
                  onClick={() => deleteContacts(selectedContacts)}
                  className={` flex justify-center w-1/2 lg:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
                >
                  Yes, Delete
                </button>
                <button
                  onClick={() => {
                    setShowModel(false);
                    setSelectedContacts([]);
                  }}
                  className=" mt-3"
                >
                  No, Keep it
                </button>
              </div>
            )}
          </div>
        )}
        {selectedOption === "Create New" && (
          <div className="rounded-md bg-white w-full mx-3 lg:mx-0 md:w-8/12 h-fit mt-16 lg:mt-8 py-6">
            <div className=" w-full flex flex-col items-center">
              <p className="text-lg text-primary font-bold mb-4">
                Create Contact
              </p>
            </div>

            <div className="w-[95%] sm:w-2/3 md:w-1/2 mx-[2%]  flex flex-col items-center md:items-start gap-3">
              <InputField
                onChange={handleChange("firstName")}
                onBlur={handleBlur("firstName")}
                title="First Name*"
                value={values?.firstName}
                placeholder="eg. Jack"
                touched={touched.firstName}
                errors={errors.firstName}
              />
              <InputField
                onChange={handleChange("lastName")}
                onBlur={handleBlur("lastName")}
                title="Last Name"
                value={values?.lastName}
                placeholder="eg. Goldberg"
                touched={touched.lastName}
                errors={errors.lastName}
              />
              <InputField
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
                title="Email"
                value={values?.email}
                placeholder="eg. jack@gmail.com"
                touched={touched.email}
                errors={errors.email}
              />

              <ContactInput
                selectedCountryCode={selectedCode}
                setSelectedCountryCode={(r) => {
                  // setCountryCode(r.Country_Code);
                  const newContact = {
                    ...values?.phone,
                    countryCode: `${r.Country_Code}`,
                  };
                  setFieldValue("phone", newContact);
                  setSelectedCode(r.Country_Code);
                  setSelectedCountry(r);
                }}
                country={selectedCountry}
                title="Phone*"
                onChange={(r) => {
                  // getContact(r.target.value, index);
                  // if (r.target.value.length > 0) {
                  //   setTouched("totalBranches", false);
                  //   setFieldError("totalBranches", false);
                  //   // setAllError(false);
                  // }
                  const newContact = {
                    ...values?.phone,
                    contact: `${r.target.value}`,
                  };
                  setFieldValue("phone", newContact);
                }}
                value={values?.phone.contact}
                placeholder="eg- 7686-8776"
              />
              <div className="relative w-full select-none">
                <div className="relative border-2 font-thin border-border rounded-md w-full px-5 caret-slate-600 transition-all duration-500 ease-in-out focus:border-secoundry">
                  <div
                    onClick={() => setIsOpen(!isOpen)}
                    className={`flex flex-row justify-between h-[56px] items-center cursor-pointer`}
                  >
                    <h2
                      className={`text-sm font-normal ${
                        selectedBots.length === 0
                          ? "text-appgray"
                          : "text-primary"
                      }`}
                    >
                      {allBotsSelected
                        ? "All"
                        : selectedBots.length === 0
                        ? "Select Bot"
                        : selectedBots.map((bot) => bot.name).join(", ")}
                    </h2>

                    <MdOutlineKeyboardArrowDown
                      className={`text-appgray text-xl transition-all duration-300 ease-in-out ${
                        isOpen ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  </div>
                  {isOpen && (
                    <div
                      className={`z-30 bg-white shadow-dropDownBox rounded-md absolute top-[4rem] left-0 right-0`}
                    >
                      <div
                        className={`${
                          allBotsSelected
                            ? "bg-blue-100 text-primary"
                            : "bg-white"
                        } hover:bg-blue-100 hover:text-primary px-2 py-2 transition-all duration-200 ease-in-out cursor-pointer`}
                        key="all"
                        onClick={() => {
                          handleBotSelection("All", "all");
                          setIsOpen(!isOpen);
                        }}
                      >
                        All
                      </div>
                      {listOfBots?.map((item) => (
                        <div
                          className={`${
                            selectedBots.some((bot) => bot.id === item._id)
                              ? "bg-blue-100 text-primary"
                              : "bg-white"
                          } hover:bg-blue-100 hover:text-primary px-2 py-2 transition-all duration-200 ease-in-out`}
                          key={item._id}
                          onClick={() => {
                            handleBotSelection(item.details.botName, item._id);
                          }}
                        >
                          {item.details.botName}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <h4 className="bg-white absolute -top-2 left-3 text-sm px-1 font-thin text-primary">
                  Bot
                </h4>
              </div>
            </div>
            <div className="mx-[2%] mt-3">
              <DescriptionInput
                title="Notes for Paka AI bots"
                onChange={handleChange("note")}
                onBlur={handleBlur("note")}
                value={values?.note}
                placeholder="eg - Our online organic store is dedicated to providing our customers with high-quality organic products that promote health and wellness. We offer a wide selection of organic food, ."
              />
            </div>

            <div className=" w-full flex flex-col items-center">
              <button
                type="submit"
                onClick={handleSubmit}
                className={` flex justify-center w-8/12 lg:w-1/2 xl:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
              >
                Save
              </button>
              <button
                onClick={() => {
                  setShowModel(false);
                  setSelectedCode(1);
                }}
                className=" mt-3"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {selectedOption === "Edit" && (
          <div className="  rounded-md bg-white w-full  mx-3 lg:mx-0 md:w-8/12 h-fit mt-16 lg:mt-8 py-6">
            <div className=" w-full flex flex-col items-center">
              <p className=" text-primary font-semibold mb-4">Update Contact</p>
            </div>

            <div className="w-[95%] sm:w-2/3 md:w-1/2 mx-[2%]  flex flex-col items-center md:items-start gap-3">
              <InputField
                onChange={handleChange("firstName")}
                onBlur={handleBlur("firstName")}
                title="First Name*"
                value={values?.firstName}
                placeholder="eg. Jack"
              />
              <InputField
                onChange={handleChange("lastName")}
                onBlur={handleBlur("lastName")}
                title="Last Name"
                value={values?.lastName}
                placeholder="eg. Goldberg"
              />
              <InputField
                onChange={handleChange("email")}
                onBlur={handleBlur("email")}
                title="Email"
                value={values?.email}
                placeholder="eg. jack@gmail.com"
              />

              <ContactInput
                selectedCountryCode={values?.phone?.countryCode}
                setSelectedCountryCode={(r) => {
                  // setCountryCode(r.Country_Code);
                  const newContact = {
                    ...values?.phone,
                    countryCode: `${r.Country_Code}`,
                  };
                  setFieldValue("phone", newContact);
                  setSelectedCode(r.Country_Code);
                  setSelectedCountry(r);
                }}
                country={selectedCountry}
                title="Phone*"
                onChange={(r) => {
                  // getContact(r.target.value, index);
                  // if (r.target.value.length > 0) {
                  //   setTouched("totalBranches", false);
                  //   setFieldError("totalBranches", false);
                  //   // setAllError(false);
                  // }
                  const newContact = {
                    ...values?.phone,
                    contact: `${r.target.value}`,
                  };
                  setFieldValue("phone", newContact);
                }}
                value={values?.phone?.contact}
                placeholder="eg- 7686-8776"
              />
              <div className="relative w-full select-none">
                <div className="relative border-2 font-thin border-border rounded-md w-full px-5 caret-slate-600 transition-all duration-500 ease-in-out focus:border-secoundry">
                  <div
                    onClick={() => setIsOpen(!isOpen)}
                    className={`flex flex-row justify-between h-[56px] items-center cursor-pointer`}
                  >
                    <h2
                      className={`text-sm font-normal ${
                        selectedBots.length === 0
                          ? "text-appgray"
                          : "text-primary"
                      }`}
                    >
                      {allBotsSelected
                        ? "All"
                        : selectedBots.length === 0
                        ? "Select Bot"
                        : selectedBots.map((bot) => bot.name).join(", ")}
                    </h2>

                    <MdOutlineKeyboardArrowDown
                      className={`text-appgray text-xl transition-all duration-300 ease-in-out ${
                        isOpen ? "rotate-180" : "rotate-0"
                      }`}
                    />
                  </div>
                  {isOpen && (
                    <div
                      className={`z-30 bg-white shadow-dropDownBox rounded-md absolute top-[4rem] left-0 right-0`}
                    >
                      <div
                        className={`${
                          allBotsSelected
                            ? "bg-blue-100 text-primary"
                            : "bg-white"
                        } hover:bg-blue-100 hover:text-primary px-2 py-2 transition-all duration-200 ease-in-out cursor-pointer`}
                        key="all"
                        onClick={() => {
                          handleBotSelection("All", "all");
                          setIsOpen(!isOpen);
                        }}
                      >
                        All
                      </div>
                      {listOfBots?.map((item) => (
                        <div
                          className={`${
                            selectedBots.some((bot) => bot.id === item._id)
                              ? "bg-blue-100 text-primary"
                              : "bg-white"
                          } hover:bg-blue-100 hover:text-primary px-2 py-2 transition-all duration-200 ease-in-out`}
                          key={item._id}
                          onClick={() => {
                            handleBotSelection(item.details.botName, item._id);
                          }}
                        >
                          {item.details.botName}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <h4 className="bg-white absolute -top-2 left-3 text-sm px-1 font-thin text-primary">
                  Bot
                </h4>
              </div>
            </div>
            <div className="mx-[2%] mt-3">
              <DescriptionInput
                title="Notes for Paka AI bots"
                onChange={handleChange("note")}
                onBlur={handleBlur("note")}
                value={values?.note}
                placeholder="eg - Our online organic store is dedicated to providing our customers with high-quality organic products that promote health and wellness. We offer a wide selection of organic food, ."
              />
            </div>

            <div className=" w-full flex flex-col items-center">
              <button
                type="submit"
                onClick={handleSubmit}
                className={` flex justify-center w-8/12 lg:w-1/2 xl:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
              >
                Save
              </button>
              <button
                onClick={() => {
                  setShowModel(false);
                  setSelectedContacts([]);
                }}
                className=" mt-3"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {selectedOption === "Import Contacts" && (
          <div className=" flex flex-col rounded-md items-center bg-white w-full  mx-3 lg:mx-0 md:w-1/2 h-fit mt-32 lg:mt-12 py-6">
            {/* <img
              className=" w-12 h-12"
              alt="del"
              src={require("../assets/images/trash.png")}
            /> */}
            <ContactsIcon color={"#241c51"} />
            {contactUploadLoader ? (
              <div className="my-5  w-full flex flex-col items-center">
                <ThreeCircles
                  height="60"
                  width="60"
                  color="#000032"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                />
                <p className=" mt-5 text-primary">Creating the Contacts.....</p>
              </div>
            ) : (
              <div className=" w-full flex flex-col items-center">
                <p className=" text-appgray text-[18px] mx-3 lg:mx-0 lg:w-8/12 flex text-center">
                  Please make sure you are using CSV or xlsx format provided by
                  us.
                </p>
                <input
                  type="file"
                  // ref={fileInputRef}
                  // style={{ display: "none" }}
                  onChange={handleFileChange}
                  className="mt-2"
                />
                <button
                  onClick={handleFileSubmit}
                  className={`flex justify-center w-1/2 lg:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-3 rounded-md mx-2 text-white`}
                >
                  Upload Contacts
                </button>
                <button onClick={() => setShowModel(false)} className=" mt-3">
                  Cancel
                </button>
              </div>
            )}
          </div>
        )}
        {(selectedOption === "block" || selectedOption === "unBlock") && (
          <div className=" flex flex-col rounded-md items-center bg-white w-full  mx-3 lg:mx-0 md:w-1/2 h-fit mt-32 lg:mt-12 py-6">
            {/* <ContactsIcon color={"#241c51"} /> */}
            <MdBlockFlipped className=" text-4xl" />
            {deleteLoader ? (
              <div className="my-5  w-full flex flex-col items-center">
                <ThreeCircles
                  height="60"
                  width="60"
                  color="#000032"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                />
                <p className=" mt-5 text-primary">
                  Updating the Contact status.....
                </p>
              </div>
            ) : (
              <div className=" w-full flex flex-col items-center mt-5">
                {selectedOption === "block" ? (
                  <>
                    <p className=" text-appgray text-[18px] mx-3 lg:mx-0 lg:w-8/12 flex text-center">
                      "Please make sure after blocking the contact caller will
                      not able to call the selected bots."
                    </p>
                    <button
                      onClick={() => {
                        updateContact({
                          user_id: userData?.id,
                          _id: selectedContacts[0],
                          blockStatus: true,
                        });
                      }}
                      className={` flex justify-center w-1/2 lg:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
                    >
                      Block Contact
                    </button>
                  </>
                ) : (
                  <>
                    <p className=" text-appgray text-[18px] mx-3 lg:mx-0 lg:w-9/12 flex text-center">
                      "Please make sure after unBlocking the contact, caller
                      will able to call all the bots."
                    </p>
                    <button
                      onClick={() => {
                        updateContact({
                          user_id: userData?.id,
                          _id: selectedContacts[0],
                          blockStatus: false,
                        });
                      }}
                      className={` flex justify-center w-1/2 lg:w-1/3 mt-4 transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white`}
                    >
                      UnBlock Contact
                    </button>
                  </>
                )}

                <button
                  onClick={() => {
                    setShowModel(false);
                    setSelectedContacts([]);
                  }}
                  className=" mt-3"
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        )}
      </CustomModal>
      <h1 className=" mt-6 lg:mt-0 text-2xl font-bold text-primary ">
        Contacts
      </h1>
      <div className="hidden md:flex justify-between items-center">
        <p className=" text-[11px] text-appgray">
          {pakaData?.contacts?.subHeading ||
            "The bot will be able to answer the caller by name and the service will be more personal."}
        </p>
        <div className="flex ">
          <button
            onClick={handleDownload}
            className="border-primary border-2 text-primary cursor-pointer px-1 py-2 rounded-md mx-3"
          >
            {/* <ImportIcon color={"#fff"} /> */}
            <p className="text-[14px] font-semibold">CSV Format</p>
          </button>
          <button
            onClick={() => {
              setShowModel(true);
              setSelectedOption("Import Contacts");
            }}
            className=" flex items-center bg-primary cursor-pointer  px-3 py-2 rounded-md mx-3"
          >
            <ImportIcon color={"#fff"} />
            <p className=" text-white mx-2 text-[14px] font-semibold">
              Import From CSV
            </p>
          </button>
          <button
            onClick={() => {
              setSelectedBots([]);
              setShowModel(true);
              setCreateContactData();
              setSelectedOption("Create New");
            }}
            className=" flex items-center bg-primary cursor-pointer  px-3 py-2 rounded-md mx-3"
          >
            <ContactsIcon color={"#fff"} />
            <p className=" text-white mx-2 text-[14px] font-semibold">
              New Contact
            </p>
          </button>
        </div>
      </div>
      <div className="md:hidden block items-center">
        <div className="flex justify-between">
          <p className=" text-[11px] text-appgray">
            {pakaData?.contacts?.subHeading ||
              "The bot will be able to answer the caller by name and the service will be more personal."}
          </p>
        </div>

        <div className="flex justify-between mt-2">
          <button className="flex w-[11.3rem] gap-1 items-center bg-primary cursor-pointer px-1 py-2 rounded-t-md mx-1">
            <div
              className="flex"
              onClick={() => {
                setShowModel(true);
                setSelectedOption("Import Contacts");
              }}
            >
              <ImportIcon color={"#fff"} />
              <p className=" text-white ml-1 text-[14px] font-medium">
                Import From CSV
              </p>
            </div>
            <MdOutlineKeyboardArrowDown
              className={` text-white text-2xl transition-all duration-300 ease-in-out ${
                isButtonOpen ? "rotate-180" : "rotate-0"
              }`}
              onClick={() => {
                setIsButtonOpen(!isButtonOpen);
              }}
            />
            {isButtonOpen && (
              <div
                onClick={handleDownload}
                className={`z-10 mx-1 w-[11.3rem] h-10 bg-white border-2 border-primary shadow-dropDownBox rounded-b-md absolute top-[7rem] left-3 right-0`}
              >
                <button className="flex gap-2 mt-1 ml-1">
                  <CsvIcon />
                  <p className="text-[16px] font-medium">CSV Format</p>
                </button>
              </div>
            )}
          </button>
          <button
            onClick={() => {
              setShowModel(true);
              setCreateContactData();
              setSelectedOption("Create New");
            }}
            className=" flex items-center bg-primary cursor-pointer px-1 py-2 rounded-md mx-1"
          >
            <ContactsIcon color={"#fff"} />
            <p className=" text-white mx-2 text-[14px] font-medium">
              New Contact
            </p>
          </button>

          <button
            onClick={handleDownload}
            className="hidden border-primary border-2 text-primary cursor-pointer px-1 py-2 rounded-md mx-3"
          >
            {/* <ImportIcon color={"#fff"} /> */}
            <p className="text-[14px] font-semibold">CSV Format</p>
          </button>
        </div>
        <div className="mt-3">
          <Search />
        </div>
      </div>
      <div className="static mt-4 rounded-2xl overflow-hidden shadow-tableShadow bg-white mb-12 lg:mb-0 ">
        <div className="bg-primary flex items-center px-1 lg:px-3 gap-x-3 lg:gap-x-4 py-2  ">
          {contactType.map((item, index) => (
            <div
              onClick={() => setSelectedContactType(item)}
              className={`text-white py-0.5 text-[16px] cursor-pointer ${
                selectedContactType === item && " border-b-2 border-b-white"
              }`}
              key={index}
            >
              {item}
            </div>
          ))}
          {selectedContacts.length > 0 ? (
            <div className="lg:w-[35rem] flex text-white gap-0 lg:gap-5 font-medium">
              <button
                onClick={() => {
                  setShowModel(true);
                  setSelectedOption("Delete");
                }}
                className=" text-center bg-primary border-2 border-white  px-6 lg:px-8 hover:bg-secoundry transition-all ease-in-out duration-300 rounded-md mx-3"
              >
                delete
              </button>
              <p className="mt-1 font-medium">
                - {selectedContacts.length} {"were chosen"}
              </p>
            </div>
          ) : (
            ""
          )}
          <div className="hidden md:block w-full">
            <Search />
          </div>
        </div>
        {selectedContactType === "All" && (
          <ContactsTable
            listOfContacts={search(
              ContactsList.filter(
                (contact) => contact.blockStatus === false && contact
              )
            )}
            setSelectedOption={(opt) => {
              setSelectedOption(opt);
            }}
            setShowModel={setShowModel}
            selectedContacts={selectedContacts}
            setSelectedContacts={setSelectedContacts}
            setCreateContactData={setCreateContactData}
            setSelectedBots={setSelectedBots}
            listOfBots={listOfBots}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
          />
        )}
        {selectedContactType === "Blacklisted" && (
          <ContactsTable
            listOfContacts={search(
              ContactsList.filter(
                (contact) => contact.blockStatus === true && contact
              )
            )}
            setSelectedOption={(opt) => {
              setSelectedOption(opt);
            }}
            setShowModel={setShowModel}
            selectedContacts={selectedContacts}
            setSelectedContacts={setSelectedContacts}
            setCreateContactData={setCreateContactData}
            setSelectedBots={setSelectedBots}
            listOfBots={listOfBots}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
          />
        )}
      </div>
    </div>
  );
};

export default Contacts;
