import React from "react";
import { useSelector } from "react-redux";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  groupByDayLast7Days,
  groupByDay,
  groupByMonth,
  groupByYear,
  groupByCustomDays,
  groupByMonthCustom,
} from "../../utils/helper/dashboardChartFunctions";

const CustomAreaChart = () => {
  const botFilteredCallHistory = useSelector(
    (state) => state.dashboard.botFilteredCallHistory
  );
  const graphType = useSelector((state) => state.dashboard.graphType);
  let data = [
    { name: "2023", calls: 0, msg: 0 },
    { name: "2024", calls: 0, msg: 0 },
    { name: "2025", calls: 0, msg: 0 },
  ];

  switch (graphType?.id) {
    case 0:
      data = groupByDayLast7Days(botFilteredCallHistory);
      break;
    case 1:
      data = groupByDay(botFilteredCallHistory);
      break;
    case 2:
      data = groupByMonth(botFilteredCallHistory);
      break;
    case 3:
      data = groupByYear(
        botFilteredCallHistory,
        graphType.startDate,
        graphType.endDate
      );
      break;
    case 4:
      data = groupByCustomDays(
        botFilteredCallHistory,
        graphType.startDate,
        graphType.endDate
      );
      break;
    case 5:
      data = groupByMonthCustom(
        botFilteredCallHistory,
        graphType.startDate,
        graphType.endDate
      );
      break;
    default:
      data = groupByMonth(botFilteredCallHistory);
  }

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorCalls" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#61C7FA" stopOpacity={0.3} />
            <stop offset="95%" stopColor="#61C7FA" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorMsg" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FF0066" stopOpacity={0.3} />
            <stop offset="95%" stopColor="#FF0066" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" style={{ fontSize: 12, color: "#eb4034" }} />
        <YAxis style={{ fontSize: 12, color: "#eb4034" }} domain={[0]} />
        <CartesianGrid horizontal={false} strokeDasharray="3 3" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="calls"
          stroke="#61C7FA"
          fillOpacity={1}
          fill="url(#colorCalls)"
        />
        {/* <Area
          type="monotone"
          dataKey="msg"
          stroke="#FF0066"
          fillOpacity={1}
          fill="url(#colorMsg)"
        /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CustomAreaChart;
