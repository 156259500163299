import React, { useState } from "react";

const DropZone = ({ onDrop, children }) => {
  const [highlight, setHighlight] = useState(false);

  const preventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e) => {
    preventDefault(e);
    setHighlight(true);
  };

  const handleDragLeave = (e) => {
    preventDefault(e);
    setHighlight(false);
  };

  const handleDragOver = (e) => {
    preventDefault(e);
  };

  const handleDrop = (e) => {
    preventDefault(e);
    setHighlight(false);
    const files = Array.from(e.dataTransfer.files);
    onDrop(files);
  };

  return (
    <div
      className={`dropzone ${highlight ? "highlight" : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {children}
    </div>
  );
};

export default DropZone;
