import React, { useState } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";

const AnalogTimePicker = ({ open, setOpen, setSelectedText }) => {
  const [selectedTime, setSelectedTime] = useState(dayjs());
  //   const [open, setOpen] = useState(true); // To control the picker open/close state

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
  };

  const handleAccept = (newTime) => {
    const hour = newTime.hour();
    const minute = newTime.minute();
    const selectedTime = hour + ":" + minute;
    // You can handle the logic when the user clicks OK here
    setSelectedText(selectedTime);
    setOpen(false); // Close the picker
  };

  const handleClose = () => {
    // You can handle the logic when the picker is closed or cancel is clicked here
    setOpen(false); // Close the picker
  };

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticTimePicker
          displayStaticWrapperAs={"mobile"}
          ampmInClock={false}
          open={open}
          onClose={handleClose}
          onAccept={handleAccept}
          value={selectedTime}
          onChange={handleTimeChange}
          //   ampm={false}
        />
      </LocalizationProvider>
    </StyledEngineProvider>
  );
};
export default AnalogTimePicker;
