import React, { useState, useEffect } from "react";
import InputField from "../../components/InputField";
import DescriptionInput from "../../components/DescriptionInput";
import { FaTrashAlt } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import ContactInput from "../../components/ContactInput";
import { useFormik } from "formik";
import { yourBusinessSchema } from "../../utils/schemas";
import Toggle from "../../components/Toggle";
import CountryIsoInput from "../../components/CountryIsoInput";
import DropDown from "../../components/bots/DropDown";
import TopicDropDown from "../../components/bots/TopicDropDown";
import { useSelector } from "react-redux";
import CustomModal from "../../components/CustomModal";
import CreateTopic from "../../components/bots/CreateTopic";
import CreateSupportBot from "../../components/bots/CreateSupportBot";

const YourBusiness = ({ onNext, goPrevious }) => {
  const topicList = useSelector((state) => state.app.topic);
  const supportBotList = useSelector((state) => state.app.supportBot);
  const [showModel, setShowModel] = useState(false);
  const [showType, setShowType] = useState("");
  const [selectedCode, setSelectedCode] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState({
    ISO: "US",
    Country: "United States",
    Country_Code: 1,
    ISO_CODES: "US / USA",
  });

  const [initialData, setInitialData] = useState();
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setTouched,
    setFieldError,
    values,
    errors,
    touched,
  } = useFormik({
    validationSchema: yourBusinessSchema,
    initialValues: {
      businessName: initialData != null ? initialData.businessName : "",
      businessDesc: initialData != null ? initialData.businessDesc : "",
      countryISO: initialData != null ? initialData.countryISO : "US",
      businessOperationStatus:
        initialData != null ? initialData.businessOperationStatus : true,
      totalOperations:
        initialData != null
          ? initialData.totalOperations
          : [
              {
                operationType: "",
                operation_id: "",
              },
            ],
      onlyTakeMessages:
        initialData != null ? initialData.onlyTakeMessages : false,
      totalBranches:
        initialData != null
          ? initialData.totalBranches
          : [
              {
                branchName: "",
                description: "",
                contact: "",
                countryCode: 1,
              },
            ],
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      goNext(values);
    },
  });

  // console.log(errors, "hdj");

  const getTopicName = (type, id) => {
    if (type === "Meetings") {
      const topicObject = topicList?.find((item) => item._id === id);
      return topicObject ? topicObject.topic : null;
    } else if (type === "Support") {
      const topicObject = supportBotList?.find((item) => item._id === id);
      return topicObject ? topicObject.topic : null;
    }
  };

  const optionType = (type) => {
    if (type === "Meetings") {
      return topicList;
    } else if (type === "Support") {
      return supportBotList;
    } else {
      return [];
    }
  };

  const getTopic = (value, index) => {
    const newArray = values?.totalOperations?.map((item, i) => {
      if (i === index) {
        // console.log(day, "index");
        return {
          ...item,
          operation_id: value?._id,
        };
      } else {
        return item;
      }
    });
    setFieldValue("totalOperations", newArray);
  };

  const getOperationType = (value, index) => {
    const newArray = values?.totalOperations?.map((item, i) => {
      if (i === index) {
        // console.log(day, "index");
        return {
          ...item,
          operationType: value,
        };
      } else {
        return item;
      }
    });
    setFieldValue("totalOperations", newArray);
  };

  const getBranchName = (value, index) => {
    const newArray = values?.totalBranches?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          branchName: value,
        };
      } else {
        return item;
      }
    });
    setFieldValue("totalBranches", newArray);
  };

  const getBusinessDesc = (value, index) => {
    const newArray = values?.totalBranches?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          description: value,
        };
      } else {
        return item;
      }
    });
    setFieldValue("totalBranches", newArray);
  };

  const setCountryCode = (value) => {
    const newArray = values?.totalBranches?.map((item, i) => {
      return {
        ...item,
        countryCode: value,
      };
    });
    setFieldValue("totalBranches", newArray);
  };

  const getContact = (value, index) => {
    const newArray = values?.totalBranches?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          contact: `${value}`,
        };
      } else {
        return item;
      }
    });
    setFieldValue("totalBranches", newArray);
  };

  const goNext = () => {
    onNext();
    const formData = {
      businessName: values?.businessName,
      businessDesc: values?.businessDesc,
      businessOperationStatus: values?.businessOperationStatus,
      totalOperations: values?.totalOperations,
      totalBranches: values?.totalBranches,
      onlyTakeMessages: values?.onlyTakeMessages,
      countryISO: values?.countryISO,
    };

    localStorage.setItem("yourBusiness", JSON.stringify(formData));
  };

  useEffect(() => {
    setInitialData(JSON.parse(localStorage.getItem("yourBusiness")));
  }, []);

  return (
    <>
      <CustomModal
        isOpen={showModel}
        className={` lg:w-8/12 lg:ml-[20%] -mt-12 lg:mt-0`}
      >
        {showType === "Meetings" ? (
          <CreateTopic setShowModel={setShowModel} />
        ) : (
          <CreateSupportBot setShowModel={setShowModel} />
        )}
      </CustomModal>
      <form
        onSubmit={handleSubmit}
        className=" w-11/12  md:w-3/5 xl:w-1/2 pt-6  "
      >
        <InputField
          onChange={handleChange("businessName")}
          onBlur={handleBlur("businessName")}
          value={values?.businessName}
          maxLength={30}
          title="Business Name"
          placeholder="e.g. - The Organic Store"
        />
        <div className=" h-12 pt-1  ">
          {touched.businessName && errors.businessName && (
            <p className="  text-[11px] text-red-500">{errors.businessName}</p>
          )}
        </div>
        <div className=" my-0">
          <DescriptionInput
            title="Business Description"
            onChange={handleChange("businessDesc")}
            onBlur={handleBlur("businessDesc")}
            value={values?.businessDesc}
            maxLength={2000}
            placeholder="e.g. - The Organic Store is a premier destination for organic produce, pantry staples, eco-friendly household goods, and health and wellness products. We are committed to promoting sustainable living by offering high-quality, organic items that support local farmers and reduce environmental impact."
          />
          <div className=" h-12 pt-1  ">
            {touched.businessDesc && errors.businessDesc && (
              <p className="  text-[11px] text-red-500">
                {errors.businessDesc}
              </p>
            )}
          </div>
        </div>

        <div className="mt-2 mb-6 ">
          <div className="my-2 flex gap-4">
            <h3 className="">Disable Operations</h3>
            <Toggle
              onChange={() =>
                setFieldValue(
                  "businessOperationStatus",
                  !values?.businessOperationStatus
                )
              }
              active={values?.businessOperationStatus}
              className={`mt-2`}
            />
            <h2 className=" text-primary">Active Operations</h2>
          </div>

          {values?.businessOperationStatus ? (
            <>
              {values?.totalOperations?.map((item, index) => (
                <div key={index} className="flex">
                  <p className="w-[5%] mt-3">
                    {index + 1}
                    {"."}
                  </p>
                  <div className="w-[95%] mt-4 relative">
                    <DropDown
                      title={"Operation Type"}
                      selectedText={item.operationType}
                      setShowType={setShowType}
                      placeholder={"Select Operation"}
                      setSelectedText={(r) => {
                        getOperationType(r, index);
                      }}
                      options={["Meetings", "Support"]}
                      dropDownClassName="overflow-y-scroll min-h-24 max-h-56 z-50"
                    />
                    {/* {touched.totalOperations[index]?.operationType &&
              errors.totalOperations[index]?.operationType && (
                <p className="text-[11px] text-red-500">
                  {errors.totalOperations.operationType[index]}
                </p>
              )} */}
                    <div className="mt-4"></div>
                    <TopicDropDown
                      title={"Topic"}
                      selectedText={getTopicName(
                        item.operationType,
                        item.operation_id
                      )}
                      placeholder={"eg. Demo Paka AI"}
                      setSelectedText={(r) => {
                        getTopic(r, index);
                      }}
                      options={optionType(item.operationType)}
                      setShowModel={setShowModel}
                      dropDownClassName="overflow-y-scroll min-h-24 max-h-56 z-50"
                    />
                    {/* {touched.totalOperations[index]?.topic &&
              errors.totalOperations[index]?.topic && (
                <p className="text-[11px] text-red-500">
                  {errors.totalOperations.topic[index]}
                </p>
              )} */}
                    {index > 0 && (
                      <FaTrashAlt
                        onClick={() => {
                          let t = values?.totalOperations.filter(
                            (_, i) => i !== index
                          );
                          setFieldValue("totalOperations", t);
                        }}
                        className="absolute -right-6 cursor-pointer hover:text-red-600 top-5 text-appgray"
                      />
                    )}
                  </div>
                </div>
              ))}
              {values?.totalOperations?.length < 5 ? (
                <div
                  onClick={() => {
                    let p = [...values?.totalOperations];

                    setFieldValue(
                      "totalOperations",
                      p.concat({
                        operationType: "",
                        operation_id: "",
                      })
                    );
                  }}
                  className="bg-primary cursor-pointer h-7 w-7 mt-6 flex items-center justify-center rounded-full ml-8"
                >
                  <MdAdd className=" text-white text-2xl" />
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <div></div>
          )}
        </div>
        <div>
          <div className="flex gap-4">
            <h2 className=" text-primary">Business Branches</h2>
            <Toggle
              onChange={() =>
                setFieldValue("onlyTakeMessages", !values?.onlyTakeMessages)
              }
              active={values?.onlyTakeMessages}
              className={`mt-2`}
            />
            <h2 className=" text-primary">Only Take Messages</h2>
          </div>

          {!values?.onlyTakeMessages ? (
            <>
              {values?.totalBranches?.map((item, index) => (
                <div key={index} className="flex">
                  <p className="w-[5%] mt-10">
                    {index + 1}
                    {"."}
                  </p>
                  <div className="w-[95%] mt-10 relative">
                    <InputField
                      onChange={(n) => {
                        getBranchName(n.target.value, index);
                        if (n.target.value.length > 0) {
                          setTouched("totalBranches", false);
                          setFieldError("totalBranches", false);
                          //setAllError(false);
                        }
                      }}
                      value={item.branchName}
                      maxLength={30}
                      title="Branch Name"
                      placeholder="e.g. - Customer Service"
                    />

                    <div className=" my-8">
                      <DescriptionInput
                        onChange={(n) => {
                          getBusinessDesc(n.target.value, index);
                          if (n.target.value.length > 0) {
                            setTouched("totalBranches", false);
                            setFieldError("totalBranches", false);
                            //setAllError(false);
                          }
                        }}
                        value={item.description}
                        maxLength={400}
                        title="Branch Description"
                        placeholder="e.g. - This extension is for customers who have questions or concerns about their purchase. You can transfer a call to this extension if the customer is having trouble with their product or if they need to return or exchange an item."
                      />
                    </div>
                    <ContactInput
                      selectedCountryCode={item.countryCode}
                      setSelectedCountryCode={(r) => {
                        setCountryCode(r.Country_Code);
                        setSelectedCode(r.Country_Code);
                        setSelectedCountry(r);
                        setFieldValue("countryISO", r.ISO);
                      }}
                      country={selectedCountry}
                      title="Contact Number"
                      onChange={(r) => {
                        getContact(r.target.value, index);
                        if (r.target.value.length > 0) {
                          setTouched("totalBranches", false);
                          setFieldError("totalBranches", false);
                        }
                      }}
                      value={item.contact}
                      placeholder="eg- 7686-8776"
                    />

                    {index > 0 && (
                      <FaTrashAlt
                        onClick={() => {
                          let t = values?.totalBranches.filter(
                            (_, i) => i !== index
                          );
                          setFieldValue("totalBranches", t);
                        }}
                        className=" absolute -right-6 cursor-pointer hover:text-red-600 top-5 text-appgray"
                      />
                    )}
                  </div>
                </div>
              ))}
              {touched.totalBranches && errors.totalBranches && (
                <div className=" h-12 pt-1  ">
                  <p className="  text-[11px] text-red-500">
                    Fill missing field
                  </p>
                </div>
              )}
              {values?.totalBranches?.length < 5 ? (
                <div
                  onClick={() => {
                    let p = [...values?.totalBranches];

                    setFieldValue(
                      "totalBranches",
                      p.concat({
                        branchName: "",
                        description: "",
                        contact: "",
                        countryCode: selectedCode,
                      })
                    );
                  }}
                  className="bg-primary cursor-pointer h-7 w-7 mt-6 flex items-center justify-center rounded-full ml-8"
                >
                  <MdAdd className="  text-white text-2xl " />
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <CountryIsoInput
              selectedCountryCode={values?.totalBranches[0].countryCode}
              setSelectedCountryCode={(r) => {
                setCountryCode(r.Country_Code);
                setSelectedCode(r.Country_Code);
                setSelectedCountry(r);
                setFieldValue("countryISO", r.ISO);
              }}
              country={selectedCountry}
              title="Select Country"
              className={`mt-4`}
            />
          )}
        </div>
        <div className=" flex my-9  mt-8">
          <button
            onClick={goPrevious}
            className=" flex items-center bg-white border-2 border-primary py-1.5 px-10 hover:bg-primary hover:text-white transition-all ease-in-out duration-300 rounded-md mx-3"
          >
            Previous
          </button>
          <button
            type="submit"
            className=" transition-all duration-300 ease-in-out hover:bg-secoundry hover:border-secoundry bg-primary py-2 px-8 rounded-md mx-3 text-white"
          >
            Next
          </button>
        </div>
      </form>
    </>
  );
};

export default YourBusiness;
