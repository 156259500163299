import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contactsList: [],
  contactSearch: "",
};
export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setContactsList: (state, actions) => {
      state.contactsList = actions.payload;
    },
    setContactSearch: (state, actions) => {
      state.contactSearch = actions.payload;
    },
  },
});
export const { setContactsList, setContactSearch } = contactSlice.actions;

export default contactSlice.reducer;
