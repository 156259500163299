import * as React from "react";
const CreateBotIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={22}
    fill="none"
    {...props}
  >
    <path
      fill={props.color}
      d="M14.125 8.75c.172 0 .342.006.512.019.073-.2.113-.418.113-.644v-5a1.875 1.875 0 0 0-1.875-1.875h-3.75V.625A.6.6 0 0 0 8.5 0a.63.63 0 0 0-.625.625v.625h-3.75A1.875 1.875 0 0 0 2.25 3.125v5A1.875 1.875 0 0 0 4.125 10h6.046a6.845 6.845 0 0 1 3.954-1.25ZM4.75 5.625a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm5 0a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Zm-2.5 10c0-1.381.407-2.669 1.108-3.746h-5.72A2.263 2.263 0 0 0 .375 14.14v.862h.006c.043.975.31 2.196 1.404 3.193C2.98 19.288 5.042 20 8.5 20c.107 0 .215 0 .32-.002a6.846 6.846 0 0 1-1.57-4.373Zm12.5 0a5.625 5.625 0 1 1-11.25 0 5.625 5.625 0 0 1 11.25 0Zm-5-2.5a.625.625 0 1 0-1.25 0V15h-1.875a.625.625 0 1 0 0 1.25H13.5v1.875a.625.625 0 0 0 1.25 0V16.25h1.875a.625.625 0 0 0 0-1.25H14.75v-1.875Z"
    />
  </svg>
);
export default CreateBotIcon;
