import { convertSecondsToHMS } from "./helperFunctions";

// function to count the total duration of call from array of call object
export function callDuration(callHistory) {
  if (!Array.isArray(callHistory)) {
    return 0;
  }
  const totalCallDuration = callHistory.reduce((acc, curr) => {
    if (curr.hasOwnProperty("callDuration")) {
      return acc + parseInt(curr.callDuration);
    }
    return acc;
  }, 0);
  return totalCallDuration;
}

// function to count total number of calls Answered by Human
export function countHumanAnsweredCalls(callHistory, status) {
  if (!Array.isArray(callHistory)) {
    return 0;
  }
  let count = 0;
  for (let i = 0; i < callHistory.length; i++) {
    if (
      callHistory[i].hasOwnProperty("status") &&
      callHistory[i].status === status
    ) {
      count++;
    }
  }
  return count;
}

// function to get average call duration
export function avgCallDuration(callHistory) {
  if (!Array.isArray(callHistory)) {
    return 0;
  }
  const totalNumberOfCalls = callHistory.length || 1;
  const totalCallsDuration = callDuration(callHistory) || 0;
  const avgDuration = convertSecondsToHMS(
    Math.round(totalCallsDuration / totalNumberOfCalls)
  );
  return avgDuration;
}

// function to get number of message
export function getNoOfMessages(callHistory) {
  if (!Array.isArray(callHistory)) {
    return 0;
  }
  const totalMsg = callHistory?.reduce(
    (count, call) => (call?.voicemail_id !== null ? count + 1 : count),
    0
  );
  return totalMsg;
}

// function to get branches and number of call to that branch
export function countBranches(callHistory, multiBot) {
  const branches = {};

  for (let i = 0; i < callHistory.length; i++) {
    const branch = callHistory[i].branch;
    const botName = callHistory[i].botName;

    if (branch !== undefined && branch !== null) {
      let branchKey;
      if (multiBot) {
        branchKey = botName ? `${branch} (${botName})` : branch;
      } else {
        branchKey = botName ? `${branch}` : branch;
      }

      if (branches[branchKey]) {
        branches[branchKey]++;
      } else {
        branches[branchKey] = 1;
      }
    }
  }

  const result = [];

  for (const branch in branches) {
    result.push({ branch, numberOfCalls: branches[branch] });
  }

  return result;
}

// function to conut the total number of call to country
export function countByCountry(callHistory) {
  const result = [];
  const counts = {};
  for (const call of callHistory) {
    const country = call.callerCountry;
    if (country) {
      counts[country] = (counts[country] || 0) + 1;
    }
  }
  for (const [country, count] of Object.entries(counts)) {
    result.push({ id: country, value: count });
  }
  return result;
}

// Function to count TopFAQs
export function countFAQs(callHistory) {
  const questionFrequency = {};
  callHistory.forEach((call) => {
    if (call.topFAQs) {
      call.topFAQs.forEach((question) => {
        if (questionFrequency[question]) {
          questionFrequency[question]++;
        } else {
          questionFrequency[question] = 1;
        }
      });
    }
  });

  const result = Object.keys(questionFrequency).map((question) => ({
    question,
    frequency: questionFrequency[question],
  }));
  return result;
}

// function to count branches this will not show bot name
// if branch name is in only one bot
export function branchCount(callhistory) {
  let branch_counts = {};
  for (let call of callhistory) {
    if (call["branch"] == null) {
      continue;
    }
    if (!(call["branch"] in branch_counts)) {
      branch_counts[call["branch"]] = [call["botName"]];
    }
    if (!branch_counts[call["branch"]].includes(call["botName"])) {
      branch_counts[call["branch"]].push(call["botName"]);
    }
  }
  let top_branches = {};
  for (let call of callhistory) {
    if (call["branch"] == null) {
      continue;
    }
    if (branch_counts[call["branch"]].length > 1) {
      let data = `${call["branch"]} (${call["botName"]})`;
      if (data in top_branches) {
        top_branches[data] += 1;
      } else {
        top_branches[data] = 1;
      }
    } else {
      if (call["branch"] in top_branches) {
        top_branches[call["branch"]] += 1;
      } else {
        top_branches[call["branch"]] = 1;
      }
    }
  }
  return Object.entries(top_branches).map(([branch, numberOfCalls]) => ({
    branch,
    numberOfCalls,
  }));
}

// function to get geoGraphic data for map
