import React from "react";

const Toggle = ({ onChange, active, className }) => {
  return (
    <div
      className={`h-5 ${
        active ? "bg-pink-500" : "bg-appgray/40"
      } w-11 rounded-lg relative ${className}`}
    >
      <div
        onClick={onChange}
        className={`h-4 w-4 rounded-full   absolute ${
          active ? "right-0 bg-white mr-[1px]" : "left-0 bg-white"
        } top-[2px] cursor-pointer ml-[2px]`}
      />
    </div>
  );
};

export default Toggle;
