import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdCheckBox } from "react-icons/md";
import { PiNotepad } from "react-icons/pi";
import { contactBots } from "../../utils/contacts/functions/contactBots";

const ContactCard = ({
  item,
  index,
  setShowModel,
  selectedContacts,
  setSelectedContacts,
  setCreateContactData,
  toggleIndividualSelected,
  indexOfFirstContact,
  setSelectedOption,
  setSelectedBots,
  listOfBots,
  toggleContactId,
  setToggleContactId,
}) => {
  const [allOptions] = useState(["Edit", "block", "Delete"]);
  const [blockOptions] = useState(["Edit", "unBlock", "Delete"]);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    setCreateContactData(item);
    setSelectedContacts([item._id]);
    setShowModel(true);
    setSelectedBots(contactBots(listOfBots, item?.bots) || []);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div
      key={item._id}
      className={`grid grid-cols-7 xl:gap-x-6 mx-2 py-3 hover:bg-primaryLight ${
        selectedContacts.includes(item._id)
          ? "bg-secoundryLight"
          : "bg-slate-50"
      } rounded-lg mt-2 py-1`}
    >
      <div
        className="w-full flex text-center cursor-pointer text-[14.86px] text-ttt pl-5"
        onClick={() => toggleIndividualSelected(item._id)}
      >
        {selectedContacts.includes(item._id) ? (
          <MdCheckBox className=" text-secoundry text-xl mr-1 mt-1" />
        ) : (
          <p className="ml-1">{indexOfFirstContact + index + 1}</p>
        )}
      </div>
      <p className=" text-[14.86px] text-ttt">{item?.firstName}</p>
      <p className=" text-[14.86px] text-ttt">{item?.lastName}</p>
      <p className=" text-[14.86px] text-ttt ">
        +{item?.phone?.countryCode}
        {item?.phone?.contact}
      </p>
      {item?.email.length > 5 ? (
        <p className="text-[14.86px] text-ttt ml-2">{item?.email}</p>
      ) : (
        <p className="ml-10">-</p>
      )}
      <div className={`text-[24px] ml-9 capitalize `}>
        {item?.note?.length > 0 ? (
          <PiNotepad title={item?.note} className="cursor-pointer" />
        ) : (
          <p className="ml-3 text-[14.86px] text-ttt">-</p>
        )}
      </div>
      <div className=" flex items-center justify-between">
        <div className="text-[20px] h-5 w-5 filter text-primary cursor-pointer transition-all duration-300 ease-in-out hover:h-6 hover:w-6">
          {item?.bots?.length}
        </div>
        <div ref={ref} className="relative pr-2">
          <button
            onClick={() => {
              setIsOpen(!isOpen);
              setToggleContactId(item._id);
            }}
          >
            <BsThreeDotsVertical className={""} />
          </button>
          {isOpen && toggleContactId === item._id && (
            <div
              className="relative inset-0 flex items-center justify-center z-50"
              onClick={() => setIsOpen(false)}
            >
              <div
                className="absolute bg-white shadow-dropDownBox rounded-lg overflow-hidden right-6"
                onClick={(e) => e.stopPropagation()}
              >
                <ul>
                  {item?.blockStatus
                    ? blockOptions.map((option) => (
                        <li
                          onClick={() => handleOptionClick(option)}
                          className="cursor-pointer py-1 px-2 transition-all rounded-lg duration-300 ease-in-out hover:bg-primary hover:text-white"
                          key={option}
                        >
                          {option}
                        </li>
                      ))
                    : allOptions.map((option) => (
                        <li
                          onClick={() => handleOptionClick(option)}
                          className="cursor-pointer py-1 px-2 transition-all rounded-lg duration-300 ease-in-out hover:bg-primary hover:text-white"
                          key={option}
                        >
                          {option}
                        </li>
                      ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
