import React, { useEffect, useState } from "react";

const InputField = ({
  title,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  value,
  className,
  titleClassName,
  maxLength,
  touched,
  errors,
}) => {
  const [charCount, setCharCount] = useState(value?.length);

  useEffect(() => {
    // Update charCount when the component mounts or when the value prop changes
    setCharCount(value?.length);
  }, [value]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setCharCount(inputValue?.length);
    onChange(event);
  };

  return (
    <div className="relative w-full">
      <input
        placeholder={placeholder}
        type="text"
        onChange={handleInputChange}
        aria-multiline={true}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        maxLength={maxLength}
        className={`outline-none text-sm border-2 font-thin ${
          touched && errors ? "border-red-800" : "border-border"
        } rounded-md h-[56px] w-full pl-3 caret-slate-600 transition-all duration-500 ease-in-out focus:border-pink-300 ${className}`}
      />
      <h1
        className={`${titleClassName} bg-white absolute -top-2 left-3 text-primary font-thin text-sm px-1`}
      >
        {title}
      </h1>
      {maxLength ? (
        <div className="absolute bottom-4 right-3 text-xs text-gray-500">
          {charCount}/{maxLength}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default InputField;
