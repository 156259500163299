import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardFilteredBot: { _id: 0, botName: "All" },
  botFilteredCallHistory: [],
  botFilteredMeetings: [],
  botFilteredVoiceMails: [],
  dateFilteredCallHistory: [],
  dateFilteredMeetings: [],
  dateFilteredVoiceMails: [],
  allBotReClick: 0,
  graphType: {id:1, startDate:"2018-01-01 01:48", endDate:"2030-01-01 01:48"},
  dayFilterType: 2,
};
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardFilteredBot: (state, actions) => {
      state.dashboardFilteredBot = actions.payload;
    },
    setBotFilteredCallHistory: (state, actions) => {
      state.botFilteredCallHistory = actions.payload;
    },
    setBotFilteredMeetings: (state, actions) => {
      state.botFilteredMeetings = actions.payload;
    },
    setBotFilteredVoiceMails: (state, actions) => {
      state.botFilteredVoiceMails = actions.payload;
    },
    setDateFilteredCallHistory: (state, actions) => {
      state.dateFilteredCallHistory = actions.payload;
    },
    setDateFilteredMeetings: (state, actions) => {
      state.dateFilteredMeetings = actions.payload;
    },
    setDateFilteredVoiceMails: (state, actions) => {
      state.dateFilteredVoiceMails = actions.payload;
    },
    setAllBotReClick: (state, actions) => {
      state.allBotReClick = actions.payload;
    },
    setGraphType: (state, actions) => {
      state.graphType = actions.payload;
    },
    setDayFilterType: (state, actions) => {
      state.dayFilterType = actions.payload;
    },
  },
});
export const {
  setDashboardFilteredBot,
  setBotFilteredCallHistory,
  setBotFilteredMeetings,
  setBotFilteredVoiceMails,
  setDateFilteredCallHistory,
  setDateFilteredMeetings,
  setDateFilteredVoiceMails,
  setAllBotReClick,
  setGraphType,
  setDayFilterType,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
