// function to get Name from contact Number
export function findContact(contactNumber, contactList) {
  // Remove non-digit characters from the input number
  const cleanContactNumber = contactNumber.replace(/\D/g, "");

  // Iterate through the contactList to find a matching contact
  const matchingContact = contactList.find((contact) => {
    const fullContactNumber =
      "+" + contact.phone.countryCode + contact.phone.contact;
    const nonPlusContactNumber =
      contact.phone.countryCode + contact.phone.contact;
    return (
      nonPlusContactNumber === cleanContactNumber ||
      fullContactNumber === contactNumber ||
      fullContactNumber === cleanContactNumber ||
      contact.phone.contact === cleanContactNumber
    );
  });

  // Return the contact name if a match is found, otherwise return the contactNumber
  return matchingContact
    ? matchingContact.firstName + " " + matchingContact.lastName
    : contactNumber;
}
