import * as React from "react";
const NewMeetingIcon = (props) => (
  <svg
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.60443 2.2915C7.60443 1.91181 7.29662 1.604 6.91693 1.604C6.53724 1.604 6.22943 1.91181 6.22943 2.2915V3.73916C4.91004 3.8448 4.04388 4.10409 3.40753 4.74044C2.77118 5.37679 2.5119 6.24295 2.40625 7.56234H20.5942C20.4886 6.24295 20.2293 5.37679 19.593 4.74044C18.9566 4.10409 18.0905 3.8448 16.7711 3.73916V2.2915C16.7711 1.91181 16.4633 1.604 16.0836 1.604C15.7039 1.604 15.3961 1.91181 15.3961 2.2915V3.67833C14.7862 3.6665 14.1027 3.6665 13.3336 3.6665H9.66693C8.89782 3.6665 8.21426 3.6665 7.60443 3.67833V2.2915Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.33203 11C2.33203 10.2309 2.33203 9.54736 2.34386 8.9375H20.6535C20.6654 9.54726 20.6654 10.2307 20.6654 10.9996V12.8333C20.6654 16.2903 20.6654 18.0188 19.5914 19.0927C18.5175 20.1667 16.789 20.1667 13.332 20.1667H9.66536C6.20839 20.1667 4.47992 20.1667 3.40597 19.0927C2.33203 18.0188 2.33203 16.2903 2.33203 12.8333V11ZM11.499 11.5337C11.7752 11.5337 11.999 11.7575 11.999 12.0337V14.0522H14.123C14.3992 14.0522 14.623 14.2761 14.623 14.5522C14.623 14.8284 14.3992 15.0522 14.123 15.0522H11.999V17.0708C11.999 17.3469 11.7752 17.5708 11.499 17.5708C11.2229 17.5708 10.999 17.3469 10.999 17.0708V15.0522H8.87793C8.60179 15.0522 8.37793 14.8284 8.37793 14.5522C8.37793 14.2761 8.60179 14.0522 8.87793 14.0522H10.999V12.0337C10.999 11.7575 11.2229 11.5337 11.499 11.5337Z"
      fill={props.color}
    />
  </svg>
);
export default NewMeetingIcon;
