import * as React from "react";
const ImportIcon = (props) => (
  <svg
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3 11C3 15.9706 6.80558 20 11.5 20C16.1944 20 20 15.9706 20 11"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      // fill={props.color}
    />
    <path
      d="M11.5 3V13M11.5 13L14 10M11.5 13L9 10"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      // fill={props.color}
    />
  </svg>
);
export default ImportIcon;
