import React from "react";
import { useSelector } from "react-redux";

const Invoices = () => {
  const invoiceData = useSelector((state) => state.app.invoicesData);
  return (
    <div className="mt-5 rounded-[10px] bg-white box-border border-[1px] border-solid border-[#c8c8c8] overflow-hidden">
      <div className="w-full h-10 border-b text-primary text-[16px] font-medium p-2 pl-4">
        Balance: $0.00
      </div>
      <div className=" overflow-x-auto scrollbar-hide scroll-smooth">
        <div className="m-3 w-[760px] md:w-[98%]">
          <div className="bg-blue-100 text-[#7F7F7F] text-[16px] font-medium flex gap-3 justify-between mt-1 md:mx-1 rounded-t-xl px-3 md:px-5 py-4 lg:text-[13px]">
            <p className="basis-[14.28%] flex justify-center ">Invoice Id </p>
            <p className="basis-[14.28%] flex justify-center ">Issue date </p>
            <p className="basis-[14.28%] flex justify-center ">Paid on </p>
            <p className="basis-[14.28%] flex justify-center ">Amount paid</p>
            <p className="basis-[14.28%] flex justify-center ">Total</p>
            <p className="basis-[14.28%] flex justify-center ">
              Download Invoice
            </p>
            <p className="basis-[14.28%] flex justify-center ">Status</p>
          </div>
          {invoiceData?.map((invoice) => (
            <div
              key={invoice?.id}
              className="bg-blue-100 text-primary flex gap-3 justify-between mt-1 md:mx-1 rounded-b-xl px-3 md:px-5 py-4 text-[16px] font-medium lg:text-[13px]"
            >
              <p className="basis-[14.28%] flex justify-center text-[#FF0000]">
                #{invoice?.id}
              </p>
              <p className="basis-[14.28%] flex justify-center ">
                {invoice?.payout_date}
              </p>
              <p className="basis-[14.28%] flex justify-center ">
                {invoice?.payout_date}
              </p>
              <p className="basis-[14.28%] flex justify-center ">
                ${invoice?.amount}
              </p>
              <p className="basis-[14.28%] flex justify-center ">
                ${invoice?.amount}
              </p>
              <p
                className="basis-[14.28%] flex justify-center hover:text-[#FF0066] cursor-pointer underline"
               // onClick={() => downloadInvoice(invoice?.receipt_url)}
               title="You can download by using CTRL + P, after clicking view invoice"
              >
                <a href={invoice?.receipt_url} target="_blank" rel="noreferrer">View Invoice</a>
              </p>
              <p className="basis-[14.28%] flex justify-center text-[#10C300] ">
                {invoice?.status}
                {"paid"}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Invoices;

