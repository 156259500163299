import React, { useState } from "react";
import { FiInfo } from "react-icons/fi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

const DropDown = ({
  title,
  placeholder,
  selectedText,
  options,
  setSelectedText,
  height,
  dropDownClassName,
  className,
  info,
  unitType,
  touched,
  errors,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`relative w-full mt-5 select-none ${className}`}>
      <div
        className={`relative border-2 font-thin ${
          touched && errors ? "border-red-800" : "border-border"
        } rounded-md w-full px-5 caret-slate-600 transition-all duration-500 ease-in-out focus:border-secoundry bg-[#F8F8F8]`}
      >
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={`flex flex-row justify-between ${height} h-[48px] items-center cursor-pointer`}
        >
          <h2
            className={`text-sm font-normal  ${
              selectedText === "" ? "text-appgray" : " text-primary"
            }`}
          >
            {selectedText === "" ? placeholder : selectedText + " " + unitType}
          </h2>
          <MdOutlineKeyboardArrowDown
            className={` text-appgray text-xl transition-all duration-300 ease-in-out mr-3 ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
          />
          {/* <div className="absolute bottom-4 right-1 text-xl text-gray-500" title={info}><FiInfo /></div> */}
          <div className="tooltip-container absolute bottom-4 right-3 text-xl text-gray-500">
            <FiInfo />
            <span
              className="md:hidden tooltip-text invisible bg-white text-primery text-center shadow-card rounded py-1 px-3 absolute z-10 opacity-0 transition-opacity duration-300"
              style={{
                top: "125%",
                left: "50%",
                transform: "translateX(-90%)",
              }}
            >
              {info}
            </span>
            <span className="hidden md:inline tooltip-text shadow-card">
              {info}
            </span>
          </div>
        </div>
        {isOpen && (
          <div
            className={`z-30 bg-white shadow-dropDownBox rounded-md absolute ${dropDownClassName} top-[4rem] left-0 right-0`}
          >
            {options?.map((item, index) => (
              <div
                className={`${
                  item === selectedText
                    ? " bg-blue-100 text-primary"
                    : "bg-white"
                } hover:bg-blue-100 hover:text-primary  px-2 py-2 transition-all duration-200 ease-in-out`}
                key={index}
                onClick={() => {
                  setSelectedText(item);
                  setIsOpen(false);
                }}
              >
                {item} {unitType}
              </div>
            ))}
          </div>
        )}
      </div>
      <h4 className=" bg-white absolute -top-7 text-base px-1 font-thin text-primary ">
        {title}
      </h4>
    </div>
  );
};

export default DropDown;
