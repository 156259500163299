export function getCallHistoryStatus(startDate, endDate) {
  const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (
    diffDays <= 28 ||
    (diffDays > 28 &&
      new Date(endDate).getMonth() + 1 === new Date(startDate).getMonth() + 1 &&
      new Date(endDate).getFullYear() === new Date(startDate).getFullYear())
  ) {
    return { id: 4, startDate, endDate };
  } else if (
    diffDays > 28 &&
    new Date(endDate).getMonth() + 1 !== new Date(startDate).getMonth() + 1 &&
    new Date(endDate).getFullYear() === new Date(startDate).getFullYear()
  ) {
    return { id: 5, startDate, endDate };
  } else if (
    diffDays > 28 &&
    new Date(endDate).getFullYear() !== new Date(startDate).getFullYear()
  ) {
    // Check if the month difference is greater than 12
    const monthDiff =
      (new Date(endDate).getFullYear() - new Date(startDate).getFullYear()) *
        12 +
      new Date(endDate).getMonth() -
      new Date(startDate).getMonth();

    if (monthDiff > 12) {
      return { id: 3, startDate, endDate };
    } else {
      return { id: 5, startDate, endDate };
    }
  }
}

// function to get last7Days data for graph
export function groupByDayLast7Days(callHistory) {
  if (!Array.isArray(callHistory)) {
    return [
      {
        name: 0,
        calls: 0,
        msg: 0,
      },
    ];
  }

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const days = Array.from({ length: 8 }, (_, i) => i + 1);

  const result = days.map((day) => {
    const dayOfMonth = currentDay - (days.length - day);
    if (dayOfMonth <= 0) {
      // If dayOfMonth is negative, subtract it from the last day of the previous month
      const prevMonthLastDay = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      ).getDate();
      return {
        name: prevMonthLastDay + dayOfMonth,
        calls: 0,
        msg: 0,
      };
    } else {
      return {
        name: dayOfMonth,
        calls: 0,
        msg: 0,
      };
    }
  });

  const convertedArr = callHistory.map((obj) => ({
    ...obj,
    date: new Date(obj.date),
  }));

  const last7DaysArr = convertedArr.filter((obj) => {
    const today = new Date();
    const diffTime = Math.abs(today - obj.date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 8;
  });

  const resultIndexMap = {};
  result.forEach((entry, index) => {
    resultIndexMap[entry.name] = index;
  });

  last7DaysArr.forEach((call) => {
    const callDate = new Date(call.date).getDate(); // Extract day of the month from call date

    if (resultIndexMap.hasOwnProperty(callDate)) {
      const resultIndex = resultIndexMap[callDate];

      // Increment the calls count for the corresponding date
      result[resultIndex].calls++;

      // Increment the msg count if voicemail_id is not null
      if (call.voicemail_id) {
        result[resultIndex].msg++;
      }
    }
  });

  return result;
}

// function to get day wise data for graph
export function groupByDay(callHistory) {
  if (!Array.isArray(callHistory)) {
    return [
      {
        name: 0,
        calls: 0,
        msg: 0,
      },
    ];
  }
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const date = new Date(callHistory[0]?.date || today);
  const daysInMonth = new Date(date.getFullYear(), currentMonth, 0).getDate();
  const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  const result = days.map((day) => ({
    name: day,
    calls: 0,
    msg: 0,
  }));

  const convertedArr = callHistory.map((obj) => ({
    ...obj,
    date: new Date(obj.date),
  }));

  convertedArr.forEach((obj) => {
    const dayIndex = obj.date.getDate() - 1;
    if (
      dayIndex >= 0 &&
      dayIndex < daysInMonth &&
      obj.date.getMonth() === currentMonth - 1
    ) {
      if (obj?._id) {
        result[dayIndex].calls++;
      }
      if (obj?.voicemail_id) {
        result[dayIndex].msg++;
      }
    }
  });

  return result;
}

// function to get month wise data for graph
export function groupByMonth(callHistory) {
  if (!Array.isArray(callHistory)) {
    return 0;
  }

  // Get the current date
  const currentDate = new Date();

  // Create an array of months for the past six months and upcoming six months
  const months = Array.from({ length: 7 }, (_, i) => {
    const monthDate = new Date(currentDate);
    monthDate.setMonth(currentDate.getMonth() + i - 6); // Adjust for past and upcoming months
    return monthDate.toLocaleString("en", { month: "short", year: "2-digit" });
  });

  const result = months.map((month) => ({
    name: month,
    calls: 0,
    msg: 0,
  }));

  const convertedArr = callHistory.map((obj) => ({
    ...obj,
    date: new Date(obj.date),
  }));

  convertedArr.forEach((obj) => {
    const monthIndex = obj.date.getMonth();
    const year = obj.date.getFullYear();
    const yearMonth = `${months[monthIndex]} ${year}`;

    const resultIndex = months.indexOf(yearMonth);

    if (monthIndex >= 0 && monthIndex < 12 && resultIndex !== -1) {
      if (obj?._id) {
        result[resultIndex].calls++;
      }
      if (obj?.voicemail_id) {
        result[resultIndex].msg++;
      }
    }
  });
  return result;
}

// Group by month for custom days
export function groupByMonthCustom(callHistory, startDate, endDate) {
  if (!Array.isArray(callHistory)) {
    return 0;
  }

  const start = new Date(startDate);
  const end = new Date(endDate);

  // Get the current date
  // const currentDate = new Date();

  // Create an array of months for the specified range
  const months = [];
  let currentDatePointer = new Date(start);

  while (currentDatePointer <= end) {
    months.push(
      currentDatePointer.toLocaleString("en", {
        month: "short",
        year: "2-digit",
      })
    );
    currentDatePointer.setMonth(currentDatePointer.getMonth() + 1);
    currentDatePointer.setDate(1);
  }

  const result = months.map((month) => ({
    name: month,
    calls: 0,
    msg: 0,
  }));

  const convertedArr = callHistory.map((obj) => ({
    ...obj,
    date: new Date(obj.date),
  }));

  convertedArr.forEach((obj) => {
    const monthIndex = obj.date.getMonth();
    const yearMonth = obj.date.toLocaleString("en", {
      month: "short",
      year: "2-digit",
    });

    const resultIndex = months.indexOf(yearMonth);

    if (monthIndex >= 0 && monthIndex < 12 && resultIndex !== -1) {
      if (obj?._id) {
        result[resultIndex].calls++;
      }
      if (obj?.voicemail_id) {
        result[resultIndex].msg++;
      }
    }
  });

  return result;
}

// function to get year wise data for graph
export function groupByYear(callHistory, startDate, endDate) {
  if (!Array.isArray(callHistory)) {
    console.error("Invalid callHistory:", callHistory);
    return 0;
  }

  const startYear = new Date(startDate).getFullYear();
  const endYear = new Date(endDate).getFullYear() + 2;

  const years = Array.from(
    { length: endYear - startYear },
    (_, i) => startYear + i
  ).sort((a, b) => a - b);

  const result = years.map((year) => ({
    name: year,
    calls: 0,
    msg: 0,
  }));

  const convertedArr = callHistory.map((obj) => ({
    ...obj,
    date: new Date(obj.date),
  }));

  convertedArr.forEach((obj) => {
    const yearIndex = years.indexOf(obj.date.getFullYear());

    if (yearIndex >= 0 && yearIndex < years.length) {
      if (obj?._id) {
        result[yearIndex].calls++;
      }
      if (obj?.voicemail_id) {
        result[yearIndex].msg++;
      }
    }
  });

  return result;
}

// function to deal with custom Days function
export function groupByCustomDays(callHistory, startDate, endDate) {
  if (!Array.isArray(callHistory)) {
    return [
      {
        name: 0,
        calls: 0,
        msg: 0,
      },
    ];
  }

  const startDateTime = new Date(startDate);
  const endDateTime = new Date(endDate);

  const result = [];
  let currentDate = new Date(startDateTime);

  while (currentDate <= endDateTime) {
    currentDate.setHours(0, 0, 0, 0);
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;

    const countCallsAndMsg = callHistory.reduce(
      (acc, obj) => {
        const objDate = new Date(obj.date);
        objDate.setHours(0, 0, 0, 0);

        const objDay = objDate.getDate();
        const objMonth = objDate.getMonth() + 1;

        if (objDay === currentDay && objMonth === currentMonth) {
          if (obj?._id) {
            acc.calls++;
          }
          if (obj?.voicemail_id) {
            acc.msg++;
          }
        }

        return acc;
      },
      { calls: 0, msg: 0 }
    );

    const callsForDay = countCallsAndMsg.calls;
    const msgForDay = countCallsAndMsg.msg;

    result.push({
      name: currentDay,
      calls: callsForDay,
      msg: msgForDay,
    });

    currentDate.setDate(currentDate.getDate() + 1);
    if (currentDate > endDateTime && result.length === 0) {
      break;
    }
  }

  return result;
}
