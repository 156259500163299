import React, { useEffect, useState } from "react";
import { FiInfo } from "react-icons/fi";

const DescriptionInput = ({
  title,
  placeholder,
  onChange,
  onBlur,
  value,
  className,
  readOnly,
  maxLength,
  info,
}) => {
  const [charCount, setCharCount] = useState(value?.length);

  useEffect(() => {
    // Update charCount when the component mounts or when the value prop changes
    setCharCount(value?.length);
  }, [value]);

  const handleTextareaChange = (event) => {
    const textareaValue = event.target.value;
    setCharCount(textareaValue?.length);
    onChange(event);
  };

  return (
    <div className={`relative mt-7 w-[95%] ${className}`}>
      <textarea
        readOnly={readOnly}
        placeholder={placeholder}
        onChange={handleTextareaChange}
        onBlur={onBlur}
        value={value}
        maxLength={maxLength}
        className="outline-none bg-[#F8F8F8] min-h-32 border-2 text-sm placeholder:text-appgray border-border rounded-md py-3 w-full pl-4 pr-8 caret-slate-600 transition-all duration-500 ease-in-out focus:border-pink-300"
      />
      <h1 className="bg-white absolute -top-6 text-base px-1 font-thin text-primary">
        {title}
      </h1>
      {/* {maxLength ? <div className="absolute bottom-4 right-3 text-xs text-gray-500">
        {charCount}/{maxLength}
      </div> : "" } */}
      {/* <div className="absolute bottom-5 right-5 text-xl text-gray-500" title={info}><FiInfo /></div> */}
      <div className="tooltip-container absolute top-4 right-3 text-xl text-gray-500">
        <FiInfo />
        <span
          className="md:hidden tooltip-text invisible bg-white text-primery text-center shadow-card rounded py-1 px-3 absolute z-10 opacity-0 transition-opacity duration-300"
          style={{
            top: "125%",
            left: "50%",
            transform: "translateX(-90%)",
          }}
        >
          {info}
        </span>
        <span className="hidden md:inline tooltip-text shadow-card">
          {info}
        </span>
      </div>
    </div>
  );
};

export default DescriptionInput;
