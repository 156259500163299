import { toast } from "react-toastify";

export const showToast = (type, message, options = {}) => {
  const defaultOptions = {
    theme: "colored",
    position: "top-center",
    autoClose: 3000,
    progress: false,
    hideProgressBar: true,
    ...options,
  };

  const { containerId, toastId } = options;

  if (type === "success") {
    if (!toast.isActive(toastId, containerId)) {
      toast.success(message, defaultOptions);
    }
  } else if (type === "error") {
    if (!toast.isActive(toastId, containerId)) {
      toast.error(message, defaultOptions);
    }
  } else if (type === "warn") {
    if (!toast.isActive(toastId, containerId)) {
      toast.warning(message, defaultOptions);
    }
  }
};
