import * as React from "react";
const OutlookCalendarIcon = (props) => (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3836_4896)">
      <path
        d="M25.2239 12.8579C25.2253 12.6646 25.1254 12.4846 24.9606 12.3835H24.9577L24.9473 12.3778L16.3798 7.30628C16.3428 7.28129 16.3045 7.25844 16.2648 7.23786C15.934 7.0672 15.5411 7.0672 15.2103 7.23786C15.1707 7.25845 15.1323 7.28129 15.0953 7.30628L6.52781 12.3778L6.51747 12.3835C6.25562 12.5464 6.17534 12.8906 6.33818 13.1525C6.38615 13.2296 6.45224 13.2939 6.53069 13.3398L15.0982 18.4112C15.1353 18.436 15.1737 18.4589 15.2132 18.4797C15.544 18.6503 15.9369 18.6503 16.2677 18.4797C16.3072 18.4589 16.3456 18.436 16.3827 18.4112L24.9502 13.3398C25.1214 13.24 25.2259 13.056 25.2239 12.8579Z"
        fill="#0A2767"
      />
      <path
        d="M7.52637 9.47153H13.1487V14.6252H7.52637V9.47153ZM24.0737 4.23271V1.87521C24.0872 1.28578 23.6207 0.796839 23.0312 0.782715H8.4412C7.85177 0.796839 7.38522 1.28578 7.39872 1.87521V4.23271L16.0237 6.5327L24.0737 4.23271Z"
        fill="#0364B8"
      />
      <path
        d="M7.39844 4.23291H13.1484V9.40791H7.39844V4.23291Z"
        fill="#0078D4"
      />
      <path
        d="M18.8984 4.23291H13.1484V9.40791L18.8984 14.5829H24.0734V9.40791L18.8984 4.23291Z"
        fill="#28A8EA"
      />
      <path
        d="M13.1484 9.40771H18.8984V14.5827H13.1484V9.40771Z"
        fill="#0078D4"
      />
      <path
        d="M13.1484 14.5825H18.8984V19.7575H13.1484V14.5825Z"
        fill="#0364B8"
      />
      <path
        d="M7.52734 14.6255H13.1497V19.3106H7.52734V14.6255Z"
        fill="#14447D"
      />
      <path
        d="M18.8984 14.5825H24.0734V19.7575H18.8984V14.5825Z"
        fill="#0078D4"
      />
      <path
        d="M24.9616 13.3081L24.9507 13.3139L16.3832 18.1324C16.3458 18.1554 16.3079 18.1772 16.2682 18.1968C16.1227 18.2661 15.9647 18.3052 15.8036 18.3118L15.3356 18.0381C15.296 18.0182 15.2576 17.9961 15.2206 17.972L6.53807 13.0166H6.53405L6.25 12.8579V22.6122C6.25443 23.263 6.78543 23.787 7.43622 23.7829H24.0572C24.0669 23.7829 24.0756 23.7783 24.0859 23.7783C24.2234 23.7695 24.3589 23.7412 24.4884 23.6944C24.5444 23.6706 24.5984 23.6426 24.65 23.6104C24.6885 23.5886 24.7546 23.5408 24.7546 23.5408C25.0492 23.3229 25.2235 22.9786 25.225 22.6122V12.8579C25.2248 13.0445 25.1242 13.2165 24.9616 13.3081Z"
        fill="url(#paint0_linear_3836_4896)"
      />
      <path
        opacity="0.5"
        d="M24.7658 12.8192V13.4172L15.8073 19.5852L6.53253 13.0204C6.53253 13.0173 6.52995 13.0147 6.52678 13.0147L5.67578 12.5029V12.0717L6.02653 12.0659L6.76828 12.4914L6.78552 12.4972L6.84877 12.5374C6.84877 12.5374 15.5658 17.5112 15.5888 17.5227L15.9223 17.7182C15.951 17.7067 15.9798 17.6952 16.0143 17.6837C16.0315 17.6722 24.668 12.8134 24.668 12.8134L24.7658 12.8192Z"
        fill="#0A2767"
      />
      <path
        d="M24.9616 13.3081L24.9507 13.3145L16.3832 18.1329C16.3458 18.1559 16.3079 18.1778 16.2682 18.1973C15.9355 18.3599 15.5464 18.3599 15.2136 18.1973C15.1742 18.1778 15.1359 18.1563 15.0986 18.1329L6.53117 13.3145L6.52083 13.3081C6.35542 13.2185 6.25171 13.0461 6.25 12.8579V22.6122C6.25412 23.2629 6.78493 23.787 7.4356 23.7829C7.4356 23.7829 7.43562 23.7829 7.43565 23.7829H24.0393C24.69 23.7871 25.2208 23.2629 25.225 22.6123C25.225 22.6123 25.225 22.6122 25.225 22.6122V12.8579C25.2248 13.0445 25.1241 13.2165 24.9616 13.3081Z"
        fill="#1490DF"
      />
      <path
        opacity="0.1"
        d="M16.5077 18.0615L16.3794 18.1334C16.3423 18.1571 16.3039 18.1788 16.2644 18.1984C16.1232 18.2677 15.9696 18.3084 15.8125 18.318L19.0722 22.1728L24.7583 23.543C24.9142 23.4253 25.0381 23.2705 25.1189 23.0928L16.5077 18.0615Z"
        fill="black"
      />
      <path
        opacity="0.05"
        d="M17.0884 17.7349L16.3794 18.1333C16.3423 18.157 16.3039 18.1787 16.2644 18.1983C16.1232 18.2676 15.9696 18.3083 15.8125 18.3179L17.3397 22.5286L24.7601 23.5412C25.0524 23.3217 25.2245 22.9775 25.2247 22.612V22.4861L17.0884 17.7349Z"
        fill="black"
      />
      <path
        d="M7.45175 23.7829H24.0376C24.2928 23.7842 24.5417 23.7036 24.7477 23.5529L15.335 18.0392C15.2954 18.0194 15.257 17.9973 15.22 17.9731L6.53749 13.0178H6.53347L6.25 12.8579V22.5789C6.24935 23.2432 6.78739 23.7823 7.45175 23.7829Z"
        fill="#28A8EA"
      />
      <path
        opacity="0.1"
        d="M14.3 6.43678V18.7015C14.299 19.1315 14.0375 19.5181 13.6387 19.679C13.5152 19.7321 13.3822 19.7595 13.2477 19.7595H6.25V5.95781H7.4V5.38281H13.2478C13.8286 5.38501 14.2987 5.85589 14.3 6.43678Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M13.725 7.01154V19.2763C13.7264 19.4152 13.697 19.5527 13.6387 19.6788C13.4791 20.0724 13.0975 20.3306 12.6727 20.3325H6.25V5.95757H12.6727C12.8396 5.95589 13.004 5.99751 13.15 6.07832C13.5025 6.2559 13.7249 6.61686 13.725 7.01154Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M13.725 7.01154V18.1263C13.7222 18.7069 13.2534 19.1775 12.6728 19.1825H6.25V5.95757H12.6727C12.8396 5.95589 13.004 5.99751 13.15 6.07832C13.5025 6.2559 13.7249 6.61686 13.725 7.01154Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M13.15 7.01149V18.1262C13.1494 18.7078 12.6793 19.1797 12.0978 19.1825H6.25V5.95752H12.0977C12.6792 5.95783 13.1503 6.42946 13.15 7.01092C13.15 7.01111 13.15 7.0113 13.15 7.01149Z"
        fill="black"
      />
      <path
        d="M1.55397 5.95752H12.096C12.6781 5.95752 13.15 6.42941 13.15 7.01149V17.5535C13.15 18.1356 12.6781 18.6075 12.096 18.6075H1.55397C0.971873 18.6075 0.5 18.1356 0.5 17.5535V7.01149C0.5 6.42941 0.971887 5.95752 1.55397 5.95752Z"
        fill="url(#paint1_linear_3836_4896)"
      />
      <path
        d="M3.79232 10.3784C4.05209 9.82495 4.47138 9.36179 4.99638 9.04844C5.57777 8.71558 6.23974 8.54964 6.9094 8.56888C7.53006 8.55542 8.1425 8.71276 8.67982 9.0237C9.18502 9.32498 9.59191 9.76642 9.85109 10.2945C10.1334 10.8763 10.274 11.5166 10.2617 12.1632C10.2753 12.8389 10.1306 13.5085 9.83902 14.1182C9.57366 14.6651 9.15387 15.1223 8.63152 15.4332C8.07349 15.7537 7.43831 15.9152 6.79498 15.9001C6.16106 15.9154 5.53512 15.7563 4.98545 15.4401C4.47588 15.1384 4.06389 14.6965 3.79865 14.1671C3.51471 13.5936 3.37237 12.9605 3.38351 12.3207C3.37168 11.6508 3.51143 10.9868 3.79232 10.3784ZM5.07572 13.5007C5.21422 13.8505 5.44909 14.154 5.75307 14.3758C6.06269 14.5922 6.43344 14.7038 6.81106 14.6944C7.21322 14.7103 7.60962 14.5948 7.94036 14.3654C8.24049 14.1443 8.46919 13.8401 8.59816 13.4903C8.74233 13.0997 8.81348 12.6859 8.80803 12.2696C8.81249 11.8493 8.74561 11.4313 8.61023 11.0333C8.49066 10.6741 8.26934 10.3573 7.97313 10.1214C7.65069 9.88117 7.25535 9.75933 6.85361 9.77638C6.4678 9.76639 6.08875 9.87891 5.77089 10.0978C5.46177 10.3206 5.22244 10.6267 5.08089 10.9804C4.76688 11.7913 4.76525 12.6898 5.07629 13.5018L5.07572 13.5007Z"
        fill="white"
      />
      <path
        d="M18.8984 4.23291H24.0734V9.40791H18.8984V4.23291Z"
        fill="#50D9FF"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3836_4896"
        x1="15.7375"
        y1="12.8579"
        x2="15.7375"
        y2="23.7829"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#35B8F1" />
        <stop offset="1" stopColor="#28A8EA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3836_4896"
        x1="2.69756"
        y1="5.13397"
        x2="10.9524"
        y2="19.4311"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1784D9" />
        <stop offset="0.5" stopColor="#107AD5" />
        <stop offset="1" stopColor="#0A63C9" />
      </linearGradient>
      <clipPath id="clip0_3836_4896">
        <rect
          width="24.725"
          height="23"
          fill="white"
          transform="translate(0.5 0.782715)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default OutlookCalendarIcon;
