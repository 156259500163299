import React, { useState } from "react";

const TimeInput = ({ selectedText, setSelectedText }) => {
  const [filteredTimes, setFilteredTimes] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const timeOptions = generateTimeOptions();

  // Allow partial input matching these patterns
  const timePattern = /^([01]?[0-9]?|2[0-3]?)?(:?[0-5]?[0-9]?)?$/;
  // Full valid time pattern
  // const fullTimePattern = /^([01]\d|2[0-3]):[0-5]\d$/;

  const handleTimeChange = (event) => {
    const value = event.target.value;

    if (timePattern.test(value)) {
      setSelectedText(value);
      setFilteredTimes(timeOptions.filter((time) => time.startsWith(value)));
      setShowOptions(true);
    }
  };

  const handleOptionClick = (time) => {
    setSelectedText(time);
    setShowOptions(false);
  };

  const handleInputClick = () => {
    setFilteredTimes(timeOptions);
    setShowOptions(!showOptions);
  };

  return (
    <div className="hidden md:flex flex-col items-center justify-center h-auto">
      <div className="w-24 relative">
        <input
          type="text"
          value={selectedText}
          onChange={handleTimeChange}
          onClick={handleInputClick}
          className="w-full p-2 border-b border-border focus:outline-none"
          placeholder="HH:MM"
          maxLength={5}
        />
        {showOptions && (
          <ul className="absolute w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto z-10">
            {filteredTimes.map((time, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(time)}
                className="p-2 cursor-pointer hover:bg-blue-100"
              >
                {time}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const generateTimeOptions = () => {
  const times = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      const time = `${String(hour).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}`;
      times.push(time);
    }
  }
  return times;
};

export default TimeInput;
