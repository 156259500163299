import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pakaData: {
    signUp: {
      heading: "Get started absolutely free.",
      subHeading: "Create your own voice bot!",
    },
    login: {
      heading: "Welcome to Paka AI",
      subHeading: "Your personal AI bot",
    },
    bot: {
      subHeading: "Take a look at your bots here",
      duplicateCreate: "Creating a new bot causes an additional charge.",
    },
    callHistory: {
      subHeading: "Take a look at your call history here",
    },
    messageCenter: {
      subHeading: "Take a look at your Message center here",
    },
    setting: {
      subHeading: "Personal Details",
    },
    settingPlan: {
      subHeading: "Take a look at your plan status",
      basicPlan: {
        subHeading:
          "Ideal for individuals who need quick access to basic features.",
        price: "$25",
        callDuration: "40 min",
        validity: "1 month",
        overDurationCharges: "$0.2 per additional min",
        callHistoryValidity: "Call history - saved for 30 days",
        newBotFixedCharge: "$5 per additional bot",
        noOfBots: "1 Paka AI bot",
        details: {
          price: "25",
          callDurationLimit: "40",
          additionalCallPricePerMinute: "0.2",
          additionalPerBotPrice: "5",
          whatsAppIntegrationCharge: "5",
          whatsAppMessageLimit: "100",
          additionalPerWhatsappMessagePrice: "0.05",
        },
      },
      advancedPlan: {
        subHeading:
          "Ideal for individuals who need quick access to basic features.",
        price: "$90",
        callDuration: "400 min",
        validity: "1 month",
        overDurationCharges: "$0.15 per additional min",
        callHistoryValidity: "Call history - Unlimited",
        newBotFixedCharge: "$3 per additional bot",
        noOfBots: "2 Paka AI bot",
        details: {
          price: "90",
          callDurationLimit: "400",
          additionalCallPricePerMinute: "0.15",
          additionalPerBotPrice: "3",
          whatsAppIntegrationCharge: "30",
          whatsAppMessageLimit: "1000",
          additionalPerWhatsappMessagePrice: "0.04",
        },
      },
      expertPlan: {
        subHeading:
          "Ideal for businesses who need personalized services and security for large teams.",
        price: "$500",
        callDuration: "3500 min",
        validity: "1 month",
        overDurationCharges: "$0.1 per additional min",
        callHistoryValidity: "Call history - Unlimited",
        newBotFixedCharge: "$2 per additional bot",
        noOfBots: "3 Paka AI bot",
        details: {
          price: "500",
          callDurationLimit: "3500",
          additionalCallPricePerMinute: "0.1",
          additionalPerBotPrice: "2",
          whatsAppIntegrationCharge: "100",
          whatsAppMessageLimit: "4000",
          additionalPerWhatsappMessagePrice: "0.03",
        },
      },
    },
    settingInvoice: {
      subHeading: "View and download your invoices",
    },
    meetingContent: {
      subHeading:
        "The bot will be able to schedule meetings directly in your calendar.",
      topic:
        "Enter the main topic of the meeting. This helps understand the meeting's purpose and sets expectations among participants.",
      duration:
        "Select the duration of the meeting in minutes. This determines how long the meeting will last.",
      breakTime:
        "Specify the time allocated for breaks between meetings. This helps manage the schedule by providing intervals between consecutive meetings.",
      location:
        "Enter the location where the meeting will take place. This can be a physical place or a virtual platform like Google Meet.",
      description:
        "Provide a detailed description of the meeting's purpose, topics to be discussed, and any other relevant information for the participants.",
      availability:
        "Specify the times when you are available for the meeting during the week. This helps coordinate the meeting according to your availability.",
      timeZone:
        "Select the time zone you are in. This is important for scheduling meetings with participants from different locations.",
      meetingWindow:
        "Specify how many days in advance the meeting can be scheduled. This helps plan the meeting coordination according to the given time frame.",
    },
    supportBotContent: {
      topic:
        "Enter the specific expertise area of the support bot. This helps the main bot understand the specialization of this support bot.",
      description:
        "Provide a detailed description of when to route the call to this support bot. This helps the main bot determine the appropriate scenarios for involving this support bot.",
      knowledge:
        "Enter the knowledge base of the support bot, including detailed information about the subject matter. Also, include instructions on how to communicate effectively, including tone and style.",
    },
  },
};
export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setPakaData: (state, actions) => {
      state.pakaData = actions.payload;
    },
  },
});
export const { setPakaData } = contentSlice.actions;

export default contentSlice.reducer;
